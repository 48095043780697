import React, { useEffect, useState } from "react";
import FontPicker from "font-picker-react";

const FontChange = ({ state,onchange,type,id }) => {
    // console.log("state",state[type],"",type)
    const [activeFontFamily, setActiveFontFamily] = useState('');
    useEffect(()=>{
        setTimeout(()=>{
            setActiveFontFamily(state[type])
        },1000)
       
    },[state])
    return (
        <div>
            <FontPicker
                pickerId={id}  
                apiKey="AIzaSyB9I4oEEL3jLUqXhdOOUGwr_UwjvTKAS74"
                activeFontFamily={activeFontFamily || 'Open Sans'}
                onChange={(nextFont) => {
                    // console.log("font",nextFont)
                    setActiveFontFamily(nextFont.family)
                    onchange((prevState) => ({
                        ...prevState,
                        [type]: nextFont.family,  
                    }));
                }}
            />
            <p className={`apply-font-${id}`} style={{ fontFamily: activeFontFamily }}>
                The font will be applied to this text.
            </p>
        </div>
    );
};

export default FontChange;
