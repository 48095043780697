import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const BASE_URL =
  process.env.NODE_ENV === "development"
     ? "https://3j6lq1k0-3001.inc1.devtunnels.ms/api/"
    : "https://hiremagic.in/api/";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl:BASE_URL,
   // credentials :true
  }),

  endpoints: () => ({}),
});

export default apiSlice;

