import React, { useEffect, useRef, useState } from 'react';
import "./CandidateDocument.css";
import { FaTimes } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { useGetCandidateDocumentsQuery, usePostUploadDocumentMutation } from '../../../../Redux/API/atsSlice';
import { useSearchParams } from 'react-router-dom';
import CommonTable from '../../../CommonTable/CommonTable';

const CandidateDocument = () => {
    const [addDocument, setAddDocument] = useState(false);
    const[documentData,setDocumentData] = useState([])
    const [uploadedFile, setUploadedFile] = useState(null);
    const[formData,setFormData] =useState({
        documentName:'',
        documentType:'',
        status:'',
        expiryDate:'',
    });


    const fileInputRef = useRef(null);
    const isAuth = useSelector((state) => state.auth);
    const [params] = useSearchParams();
    const candidateid = params.get("id");
    const [addDocumentData] = usePostUploadDocumentMutation();
    const {data,isSuccess,refetch}=useGetCandidateDocumentsQuery({
        domain_name:isAuth?.user?.domain_name,
        candidate_id:candidateid
    })
         const [selectedColumns, setSelectedColumns] = useState([
            "document_name", "document_type", "status", "expiry_date"
          ]);


    useEffect(()=>{
        if(data?.status){

            console.log("document data",data?.message)
            setDocumentData(data?.message)
        }
    },[data,isSuccess])
    const handleAddDocumentClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadedFile(file);
            setAddDocument(true);
        }
    };

    const handleCancel = () => {
        setAddDocument(false);
        setUploadedFile(null);
        setFormData({
            documentName:'',
            documentType:'',
            status:'',
            expiryDate:'',
        });
    };

    const handleChange =(e)=>{
        const{name,value} = e.target;
        setFormData((prev)=>({
            ...prev,
            [name]:value,
        }));
    }

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSave = async () => {
        const {documentName,documentType,status,expiryDate} = formData;
        if (!uploadedFile || !documentName || !documentType || !status || !expiryDate) {
            alert("Please fill all fields and select a file.");
            return;
        }

        try {
            const base64File = await convertFileToBase64(uploadedFile);

            const payload = {
                domain_name: isAuth?.user?.domain_name,
                candidateid: candidateid,
                data: [
                    {
                        document_name: documentName,
                        document_type: documentType,
                        document_url: base64File,
                        expiry_date: expiryDate,
                        uploaded_by: isAuth?.user?.user_id,
                        status: status === "Active" ? "1" : "0"
                    }
                ]
            };

            const res = await addDocumentData(payload);
            console.log("Upload Response:", res);
            handleCancel(); 
        } catch (err) {
            console.error("Error uploading document:", err);
        }
    };

    console.log("document",documentData)

  const handleRowAction = (job) => (
    
   
    <>
   
      {/* <button 
        onClick={() => navigate(`?type=job_openings&id=${job.job_id}`)}
        className="commonTable-dropdown-item"
      >
        <IoDocumentTextOutline /> View Job Detail
      </button> */}
      <button 
        // onClick={()=>navigate(`EditJob/${job.job_id}`)}
        className="commonTable-dropdown-item"
      >
        {/* <FaRegEdit />  */}
        Edit Job
      </button>
      {/* <button 
        onClick={() => console.log("Delete job:", job.job_id)}
        className="commonTable-dropdown-item danger"
      >
        <RiDeleteBin5Line/> Delete Job
      </button> */}
    </>
  );

  const columns = [
    { key: "document_name", label: "Document Name" },
    { key: "document_type", label: "Document Type" },
    { key: "status", label: "Status" },
    { key: "expiry_date", label: "Expiry Date" },
 
   

  ];
    return (
        <div>
            <button onClick={handleAddDocumentClick}>Add Document</button>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />

            <CommonTable
            
            data={documentData}
            columns={columns}
            onRowAction={handleRowAction}
            showSidebar={false}
            showFilterAndSortBtn={false}
            showPagination={false}
            showFilterTitle={false}
            showSearchBarContainer={false}
            selectedColumns={selectedColumns}
            onColumnChange={setSelectedColumns}
            />

            {addDocument && (
                <div className='canidate-adddocument-popup open'>
                    <div className='canidate-adddocument-popup-header'>
                        <h3>Add Document</h3>
                        <button className='canidate-adddocument-popup-close-btn' onClick={handleCancel}>
                            <FaTimes />
                        </button>
                    </div>

                    <div className='candidate-adddocument-popup-content'>
                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Document Name</label>
                            <input
                                className='candidate-adddocument-popup-input'
                                type='text'
                                name='documentName'
                                value={formData.documentName}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Document Type</label>
                            <select
                                className='candidate-adddocument-popup-input'
                                name='documentType'
                                value={formData.documentType}
                                onChange={handleChange}
                            >
                                <option value="">Select</option>
                                <option value="aadhar">Aadhar</option>
                                <option value="pan">PAN</option>
                                <option value="cover">Cover Letter</option>
                                <option value="offer">Offer Letter</option>
                            </select>
                        </div>

                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Status</label>
                            <select
                                className='candidate-adddocument-popup-input'
                                name='status'
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="">Select</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>

                        <div className='candidate-adddocument-popup-inputcontainer'>
                            <label className='candidate-adddocument-popup-label'>Expire Date</label>
                            <input
                                className='candidate-adddocument-popup-input'
                                type='date'
                                name='expiryDate'
                                value={formData.expiryDate}
                                onChange={handleChange}
                            />
                        </div>

                        {uploadedFile && (
                            <div className="candidate-adddocument-popup-inputcontainer">
                                <label className='candidate-adddocument-popup-label'>Uploaded Document</label>
                                <div className="uploaded-file-name">
                                    <p>{uploadedFile.name}</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="candidate-adddocument-popup-fixedcontainer">
                        <div className="ci-popup-line"></div>
                        <div className="candidate-adddocument-popup-bottomCon">
                            <button className="adddocument-popup-cancelbtn" onClick={handleCancel}>Cancel</button>
                            <button className="adddocument-popup-savebtn" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CandidateDocument;








