import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetLoginSettingsQuery, useUpdateLoginSettingsMutation } from "../../../../Redux/API/atsSlice";
import './LoginSetting.css'
import { showToast } from "../../../ToastContainer/ToastProvider";


const LoginSettings = () =>{
    const isAuth = useSelector((state) => state.auth);

    const {
        data, refetch, isSuccess
    } = useGetLoginSettingsQuery({
        domain_name:isAuth?.user?.domain_name
    })
    const handleCheckboxChange = (index) => {
        setSetUpData((prev) => 
            prev.map((item, i) => 
                i === index ? { ...item, metadata: !item.metadata } : item
            )
        );
    };
     const [setupData, setSetUpData] = useState([]);

         useEffect(() => {
            refetch()
            // console.log("data",data)
             if (data) {
                 setSetUpData([
                     { type: "checkbox", metadata: data?.message[0]?.is_google, meta_title: "is_google", title: "Enable Google" },
                     { type: "checkbox", metadata: data?.message[0]?.is_linkedin, meta_title: "is_linkedin", title: "Enable LinkedIn" },
                 ]);
             }
         }, [data,isSuccess]);

          const [update] = useUpdateLoginSettingsMutation() 
         const handleUpdate = async () =>{
            const  payload =  {
                role_id:data?.message[0]?.role_id
            }
            for(let i of setupData){
                payload[i.meta_title] = i?.metadata
            }
            // const data = setupData.map((item,i)=>)
            try{
                const res = await update({
                    domain_name:isAuth?.user?.domain_name,
                    data:payload
                })
            //   console.log("res",res)
                if(res)
                      showToast(res?.data?.status,res?.data?.message)
            }catch(err){
                showToast(err?.data?.status,err?.data?.message)
            }
        }
    return(
        <>
    <div className="setting-login-control-container">
        <div className="setting-login-control-header">
            <p>Login Settings</p>
            <p>Add</p>
        </div>
        <div className="setting-login-control-list">
            {setupData.map((item, i) => (
                <div key={item.meta_title} className="setting-login-control-item">
                    <p className="setting-login-control-label">{item.title}</p>
                    <input 
                        type="checkbox" 
                        checked={item.metadata || false} 
                        onChange={() => handleCheckboxChange(i)} 
                    />
                </div>
            ))}
        </div>
    </div>

    {/* Buttons Outside the Box */}
    <div className="setting-login-control-footer">
        <button className="setting-login-control-cancel-btn">Cancel</button>
        <button className="setting-login-control-save-btn" onClick={handleUpdate}>
            Save Changes
        </button>
    </div>
</>

    )
}

export default LoginSettings