// import React, { useEffect, useState } from "react";
// import { useCreateHierachyMutation, useGetHierachyQuery, useGetUsersQuery } from "../../../../Redux/API/atsSlice";
// import { useSelector } from "react-redux";
// import "./Reportees.css";

// const Reportees = () => {
//     const isAuth = useSelector((state) => state.auth);
//     const [Hierarchy, setHierarchy] = useState([]);
//     const [expandedNodes, setExpandedNodes] = useState({});

//     const { data: hierachy, isSuccess: hierachySuccess } = useGetHierachyQuery({
//         domain_name: isAuth?.user?.domain_name,
//     });

//     console.log("hierachy===>", hierachy);
    

//     useEffect(() => {
//         if (hierachySuccess && hierachy?.status) {
//             setHierarchy(hierachy?.message || []);
//         }
//     }, [hierachySuccess]);

//     const toggleExpand = (h_id) => {
//         setExpandedNodes((prev) => ({
//             ...prev,
//             [h_id]: !prev[h_id],
//         }));
//     };

//     // Render the tree dynamically
//     const renderTree = (parent_id = null) => {
//         const nodes = Hierarchy.filter(node => node.parent_id === parent_id);
//         if (nodes.length === 0) return null;

//         return (
//             <div className="tree-level">
//             {nodes.map((node,i) => {
//                 const subordinates = Hierarchy.filter(item => item.parent_id === node.h_id);
//                 return (
//                     <div key={node.h_id} className={`tree-node ${subordinates.length > 0 ? "has-children" : ""} ${i%2==0?'even':'odd'} ${i===0?'left':i===nodes.length-1?'right':""}`}>
//                         <div className="node-content">
//                             <p className="node-name">{node.label}</p>
//                             <p className="node-role">({node?.value})</p>
//                             {subordinates.length > 0 && (
//                                 <span className="expand-btn" onClick={() => toggleExpand(node.h_id)}>
//                                     {expandedNodes[node.h_id] ? "-" : subordinates.length}
//                                 </span>
//                             )}
//                         </div>
//                         {expandedNodes[node.h_id] && <div className="sub-tree">{renderTree(node.h_id)}</div>}
//                     </div>
//                 );
//             })}
//         </div>
//         );
//     };

//     return (
//         <div className="tree-container">
//             {renderTree()}
//         </div>
//     );
// };

// export default Reportees;


// import React, { useEffect, useState } from "react";
// import { Tree, TreeNode } from "react-organizational-chart";
// import { useGetHierachyQuery } from "../../../../Redux/API/atsSlice";
// import { useSelector } from "react-redux";
// import "./Reportees.css";
// import { Card, CardHeader, Avatar, Button } from "@material-ui/core";

// const Reportees = () => {
//     const isAuth = useSelector((state) => state.auth);
//     const [Hierarchy, setHierarchy] = useState([]);
//     const [expandedNodes, setExpandedNodes] = useState({});

//     const { data: hierachy, isSuccess: hierachySuccess } = useGetHierachyQuery({
//         domain_name: isAuth?.user?.domain_name,
//     });

//     useEffect(() => {
//         if (hierachySuccess && hierachy?.status) {
//             setHierarchy(hierachy?.message || []);
//         }
//     }, [hierachySuccess]);

//     const toggleExpand = (h_id) => {
//         setExpandedNodes((prev) => ({
//             ...prev,
//             [h_id]: !prev[h_id],
//         }));
//     };

//     const renderNodes = (parent_id = null) => {
//         const nodes = Hierarchy.filter(node => node.parent_id === parent_id);
//         if (nodes.length === 0) return null;

//         return nodes.map((node) => {
//             const hasChildren = Hierarchy.some(child => child.parent_id === node.h_id);
//             return (
//                 <TreeNode 
//                     key={node.h_id} 
//                     label={
//                         <Card variant="outlined">
//                             <CardHeader
//                                 avatar={<Avatar>{node.label[0]}</Avatar>}
//                                 title={node.label}
//                                 subheader={node.value}
//                                 action={
//                                     hasChildren && (
//                                         <Button size="small" onClick={() => toggleExpand(node.h_id)}>
//                                             {expandedNodes[node.h_id] ? "-" : "+"}
//                                         </Button>
//                                     )
//                                 }
//                             />
//                         </Card>
//                     }
//                 >
//                     {expandedNodes[node.h_id] && renderNodes(node.h_id)}
//                 </TreeNode>
//             );
//         });
//     };

//     const rootNode = Hierarchy.find(node => node.parent_id === null);
//     return (
//         <Tree 
//             label={
//                 rootNode && (
//                     <Card variant="outlined">
//                         <CardHeader
//                             avatar={<Avatar>{rootNode.label[0]}</Avatar>}
//                             title={rootNode.label}
//                             subheader={rootNode.value}
//                         />
//                     </Card>
//                 )
//             }
//         >
//             {renderNodes(rootNode?.h_id)}
//         </Tree>
//     );
// };

// export default Reportees;


import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useGetHierachyQuery } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";

const Reportees = () => {
  const isAuth = useSelector((state) => state.auth);
  const [Hierarchy, setHierarchy] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState({});

  const { data: hierachy, isSuccess: hierachySuccess } = useGetHierachyQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  useEffect(() => {
    if (hierachySuccess && hierachy?.status) {
      setHierarchy(hierachy?.message || []);
    }
  }, [hierachySuccess]);

  const toggleExpand = (h_id) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [h_id]: !prev[h_id],
    }));
  };

  const renderNodes = (parent_id = null) => {
    const nodes = Hierarchy.filter((node) => node.parent_id === parent_id);
    if (nodes.length === 0) return null;

    return nodes.map((node) => {
      const hasChildren = Hierarchy.some((item) => item.parent_id === node.h_id);

      return (
        <TreeNode
          key={node.h_id}
          label={
            <div style={styles.card}>
              {/* <img src="https://via.placeholder.com/40" alt="avatar" style={styles.avatar} /> */}
              <p style={styles.name}>{node.label}</p>
              <p style={styles.role}>{node.value}</p>
              {hasChildren && (
                <button
                  style={styles.expandButton}
                  onClick={() => toggleExpand(node.h_id)}
                >
                  {expandedNodes[node.h_id] ? "" : nodes.length}
                </button>
              )}
            </div>
          }
        >
          {expandedNodes[node.h_id] && renderNodes(node.h_id)}
        </TreeNode>
      );
    });
  };

  const rootNode = Hierarchy.find((node) => node.parent_id === null);

  return (
    <>
    <h3>Organization Hierarchy</h3>
    <div style={styles.container}>
      {rootNode && (
        <Tree
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
          label={
            <div style={styles.card}>
              {/* <img src="https://via.placeholder.com/40" alt="avatar" style={styles.avatar} /> */}
              <p style={styles.name}>{rootNode.label}</p>
              <p style={styles.role}>{rootNode.value}</p>
              <button
                style={styles.expandButton}
                onClick={() => toggleExpand(rootNode.h_id)}
              >
                {expandedNodes[rootNode.h_id] ? "" : Hierarchy.filter(n => n.parent_id === rootNode.h_id).length}
              </button>
            </div>
          }
        >
          {expandedNodes[rootNode.h_id] && renderNodes(rootNode.h_id)}
        </Tree>
      )}
    </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    width:'100vw',
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent:'center',
    alignItems: "center",
    padding: "15px",
    backgroundColor: "white",
    borderRadius: "4px",
    border:'1px solid #ddd',
    // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    minWidth: "150px",
    minHeight:'100px',
    position: "relative",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginBottom: "8px",
  },
  name: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#007bff",
    margin: "0",
  },
  role: {
    fontSize: "12px",
    color: "#6c757d",
    margin: "0",
  },
  expandButton: {
    position: "absolute",
    bottom: "-10px",
    backgroundColor: "#007bff",
    zIndex: 10,
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    fontSize: "14px",
    cursor: "pointer",
    display: "flex",             
  justifyContent: "center",   
  alignItems: "center",       
  padding: 0 
  },
};

export default Reportees;
