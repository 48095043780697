import React, { useEffect, useState } from 'react';
import 'react-color-palette/css';
import ColorPalette from './ColorPalette';
import './EmbededCode.css';
import FontChange from './FontChange';
import { useGetEmbededCodeSettingsQuery, useUpdateEmbededCodeSettingsMutation } from '../../../../Redux/API/atsSlice';
import { useSelector } from 'react-redux';
import { showToast } from '../../../ToastContainer/ToastProvider';

const EmbededCode = () => {
  // state to store all user-selected settings
    const [data, setdata] = useState({
        template_name: "Template 1",
        bg_color: "#4542f5",
        solids_color: "#4542f5",
        text_color: "#4542f5",
        bold_color: "#4542f5",
        button_color: "#4542f5",
        // button_font_color: '#4542f5',
        text_font: "",
        bold_font: "",
        // button_font: "",
        button_style: ""
    });

// elements is a list of colors users can change.
    const [elements, setElements] = useState([
        {
            title: "Background Color",
            type: "bg_color",
            component: <ColorPalette state={data} type={"bg_color"} onchange={setdata} />,
            color: '',
            isShow: false
        },
        {
            title: 'Solid Colors',
            type: "solids_color",
            component: <ColorPalette state={data} type={"solids_color"} onchange={setdata} />,
            color: '',
            isShow: false
        },
        {
            title: "Text Color",
            type: "text_color",
            component: <ColorPalette state={data} type={"text_color"} onchange={setdata} />,
            color: '',
            isShow: false
        },
        {
            title: "Bold Color",
            type: "bold_color",
            component: <ColorPalette state={data} type={"bold_color"} onchange={setdata} />,
            color: '',
            isShow: false
        },
        {
            title: "Button Color",
            type: "button_color",
            component: <ColorPalette state={data} type={"button_color"} onchange={setdata} />,
            color: '',
            isShow: false
        },
        // {
        //     title: "Button Font Color",
        //     type: "button_font_color",
        //     component: <ColorPalette state={data} type={"button_font_color"} onchange={setdata} />,
        //     color: '',
        //     isShow: false
        // }
    ]);
    const [font, setFont] = useState([
        {
            title: "Text Font",
            id: "textfont",
            type: "text_font"
        },
        {
            title: "Bold Font",
            id: "boldfont",
            type: "bold_font"
        },
        // {
        //     title: "Button Font",
        //     id: "buttonfont",
        //     type: "button_font"
        // }
    ]);


    //Calls an API to get saved settings.
    const isAuth = useSelector((state) => state.auth);
    const {
        data: EmbededCodeSettings,
        isSuccess,
        refetch
    } = useGetEmbededCodeSettingsQuery({
        domain_name: isAuth?.user?.domain_name
    });


    // Once we get data from the API, we need to update data. if API sends data we update data else we use default settings
    useEffect(() => {
        if (EmbededCodeSettings?.status) {
            setdata(EmbededCodeSettings?.message[0] || {
                template_name: "Template 1",
                bg_color: "#4542f5",
                solids_color: "#4542f5",
                text_color: "#4542f5",
                bold_color: "#4542f5",
                button_color: "#4542f5",
                // button_font_color: '#4542f5',
                text_font: "",
                bold_font: "",
                // button_font: "",
                button_style: ""
            });
        }
    }, [isSuccess]);

    // When users change settings and click "Save Changes", we send the updated settings to the API.
    const [updateEmbededCodeSettings] = useUpdateEmbededCodeSettingsMutation();
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await updateEmbededCodeSettings({
                domain_name: isAuth?.user.domain_name,
                template_id: data.template_id ? data.template_id : "null",
                data: data
            });
            if(response)
                showToast(response.data.status,response.data.message)
            // console.log(response);
        } catch (err) {
            console.log(err);
            showToast(err.data.status,err.data.message)
        }
    };

    
// updates the color property of each item inside the elements array whenever data changes.
    useEffect(() => {
        if (data && elements) {
            setElements(elements.map((item, i) => ({
                ...item,
                color: data[`${item?.type}`]
            })));
        }
    }, [data]);

  
// toggles the visibility of the color picker when a user clicks on a color preview box.
    function handleColorPalette(e, type) {
        e.preventDefault();
        setElements(
            elements.map((item) =>
                item.type === type
                    ? { ...item, isShow: !item.isShow }
                    : { ...item }
            ));
    }

    return (
        <>
        <div className="embeded-code-container">
            <div>
                {/* <input
                    className="template-name-input"
                    value={data.template_name}
                    type='text'
                    onChange={(e) => {
                        setdata({ ...data, template_name: e.target.value });
                    }}
                /> */}
            </div>
            <h3>Theme Setting</h3>
            <div className='color-elements-row'>  
            {elements.map((item, i) => (
                <div key={i} className="color-element">
                    <p>{item.title}</p>
                    <div
                        className="color-preview"
                        style={{ backgroundColor: item.color }}
                        onClick={(e) => { handleColorPalette(e, item.type) }}
                    ></div>
                    {item.isShow && <ColorPalette state={data} type={item.type} onchange={setdata} />}
                </div>
            ))}
            </div>

            <h3 style={{marginTop:'4%'}}>Typography Settings</h3>
 
            <div className="font-change-section">
                

                {font.map((item, i) => (
                    <div key={i}>
                        <p>{item.title}</p>
                        <FontChange state={data} type={item?.type} onchange={setdata} id={item.id} />
                    </div>
                ))}
            </div>

            <button className="save-changes-button" onClick={(e) => handleUpdate(e)}>Save Changes</button>
        </div>
        </>
    );
};

export default EmbededCode;