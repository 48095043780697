import React, { useState, useEffect } from "react";
import {
  useGetCandidateSettingsQuery,
  useUpdateCandidateSettingsMutation,
} from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import "./CandidateSetting.css";
import { showToast } from "../../../ToastContainer/ToastProvider";

const CandidateSettings = () => {
  const isAuth = useSelector((state) => state.auth);

  const { data } = useGetCandidateSettingsQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  const [setupData, setSetUpData] = useState([]);
  // console.log(setupData)
  // Update state when API data arrives
  useEffect(() => {
    if (data) {
      setSetUpData([
        {
          type: "checkbox",
          metadata: data?.message[0]?.mail_enabled,
          meta_title: "mail_enabled",
          title: "Enable Mail",
        },
        {
          type: "checkbox",
          metadata: data?.message[0]?.job_Assign,
          meta_title: "job_Assign",
          title: "Enable Assign Job",
        },
        {
          type: "checkbox",
          metadata: data?.message[0]?.submit_hiring_manager,
          meta_title: "submit_hiring_manager",
          title: "Enable Submit Hiring Manager",
        },
        {
          type: "checkbox",
          metadata: data?.message[0]?.schedule_interview,
          meta_title: "schedule_interview",
          title: "Enable Schedule Interview",
        },
        { type: "checkbox", metadata:data?.message[0]?.overview, meta_title:"overview", title:"Enable Overview"},
                { type: "checkbox", metadata:data?.message[0]?.details, meta_title:"details", title:"Enable Details Overview"},
                { type: "checkbox", metadata:data?.message[0]?.interviews, meta_title:"interviews", title:"Enable Interviews Overview"},
                { type: "checkbox", metadata:data?.message[0]?.evaluation, meta_title:"evaluation", title:"Enable Evaluation Overview"},
                { type: "checkbox", metadata:data?.message[0]?.job_submission, meta_title:"job_submission", title:"Enable Job Submission Overview"},
                { type: "checkbox", metadata:data?.message[0]?.comments, meta_title:"comments", title:"Enable Comments Overview"},
                { type: "checkbox", metadata:data?.message[0]?.documents, meta_title:"documents", title:"Enable Documents Overview"},
      ]);
    }
  }, [data]);

  // Handle checkbox toggle
  const handleCheckboxChange = (index) => {
    setSetUpData((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, metadata: !item.metadata } : item
      )
    );
  };
  const [update] = useUpdateCandidateSettingsMutation();
  const handleUpdate = async () => {
    const payload = {
      role_id: data?.message[0]?.role_id,
    };
    for (let i of setupData) {
      payload[i.meta_title] = i?.metadata;
    }
    // const data = setupData.map((item,i)=>)
    try {
      const res = await update({
        domain_name: isAuth?.user?.domain_name,
        data: payload,
      }
    );
    if(res)
      showToast(res?.data?.status,res?.data?.message)
      //  console.log("res",res)
    } catch (err) {
      showToast(err?.data?.status,err?.data?.message);
    }
  };
  return (
    <div>
      <div className="settings-candidate-control-container">
        <div className="settings-candidate-control-header">
          <p>Candidate Settings</p>
          <p>Add</p>
        </div>
        <div className="settings-candidate-control-list">
          {setupData.map((item, i) => (
            <div
              key={item.meta_title}
              className="settings-candidate-control-item"
            >
              <p className="settings-candidate-control-label">{item.title}</p>
              <input
                type="checkbox"
                checked={item.metadata || false}
                onChange={() => handleCheckboxChange(i)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="settings-candidate-control-footer">
        <button className="settings-candidate-control-cancel-btn">
          Cancel
        </button>
        <button
          className="settings-candidate-control-save-btn"
          onClick={handleUpdate}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default CandidateSettings;
