import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetCandidateJobsQuery } from '../../../../Redux/API/atsSlice';
import CommonTable from '../../../CommonTable/CommonTable';
import { FaRegEdit } from "react-icons/fa";
const CandidateJobSubmission = () => {
    const[jobData,setJobData] =useState();
    const isAuth = useSelector((state)=>state.auth);
     const [selectedColumns, setSelectedColumns] = useState([
        "id", "job_Title", "Department_Name", "City", "job_status", "created_at"
      ]);
    const[params]=useSearchParams()
    const{data,isSuccess,refetch}=useGetCandidateJobsQuery({
        domain_name:isAuth?.user?.domain_name,
        id:params.get("id") || "null",
    });
    console.log("Data",jobData)
      const navigate = useNavigate();
const [sortOrder, setSortOrder] = useState("Newest First"); // Default sorting
    const sidebarLabels = [
      "All Jobs",
      "Active Jobs",
      "Inactive Jobs",
      "Recent Jobs",
      "My Jobs",
    ];
    useEffect(()=>{
       if(isSuccess && data?.status){
        setJobData(data?.message?.jobs)
       }
    },[data,isSuccess])
    const handleRowAction = (job) => (
        
       
        <>
       
          {/* <button 
            onClick={() => navigate(`?type=job_openings&id=${job.job_id}`)}
            className="commonTable-dropdown-item"
          >
            <IoDocumentTextOutline /> View Job Detail
          </button> */}
          <button 
            onClick={()=>navigate(`EditJob/${job.job_id}`)}
            className="commonTable-dropdown-item"
          >
            <FaRegEdit /> Edit Job
          </button>
          {/* <button 
            onClick={() => console.log("Delete job:", job.job_id)}
            className="commonTable-dropdown-item danger"
          >
            <RiDeleteBin5Line/> Delete Job
          </button> */}
        </>
      );
     const [selectedFilter, setSelectedFilter] = useState("All Jobs");
    const getFilteredAndSortedJobs = () => {
      let filteredJobs = jobData? [...jobData]:[]; 
    
      // Apply status-based filtering
      if (selectedFilter === "Active Jobs") {
        filteredJobs = jobData.filter(job => job.job_status === "Active");
      } else if (selectedFilter === "Inactive Jobs") {
        filteredJobs = jobData.filter(job => job.job_status === "InActive");
      } else if (selectedFilter === "Recent Jobs") {
        filteredJobs = jobData.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } else if (selectedFilter === "My Jobs") {
        filteredJobs = jobData.filter(job => job.created_by === isAuth?.user?.user_id);
      }
    
      // Apply sorting based on selected order
      if (sortOrder === "Newest First") {
        filteredJobs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } else if (sortOrder === "Oldest First") {
        filteredJobs.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      }
    
      return filteredJobs;
    };
 

    const columns = [
      { key: "job_id", label: "Job ID" },
      { key: "job_title", label: "Job Title" },
      { key: "job_dept", label: "Department" },
      { key: "job_city", label: "Location" },
      { key: "job_status", label: "Status" },
      { key: "created_at", label: "Posted Date" },
     
  
    ];

    const columns2 = [
      { key: "id", label: "Job ID" },
      { key: "job_Title", label: "Job Title" },
      { key: "Department_Name", label: "Department" },
      { key: "City", label: "Location" },
      { key: "job_status", label: "Status" },
      { key: "created_at", label: "Posted Date" },
    ];
  
  return (
    <div>
    <CommonTable
        module_name={"job_openings"}
          detailNavigate={"/ats/dashboard/?type=job_openings"}
          headerButton="Add Job"
         // headerIcon={<CiSquarePlus />}
          headerNavigateTo="/ats/dashboard/?type=createJob"
          columns={columns}
          // data={jobData}
          meta_title={"Jobs"}
          data={getFilteredAndSortedJobs()} // Pass the filtered data
          onRowAction={handleRowAction}
          title={selectedFilter}
          sidebarLabels={sidebarLabels} 
          rowIdentifier="job_id"
          privilege={"Job Create"}
          onFilterChange={setSelectedFilter} // Pass filter change handler
          onSortChange={setSortOrder} // ✅ Handle sorting change
          selectedColumns={selectedColumns} // ✅ Pass selected columns
          onColumnChange={setSelectedColumns} // ✅ Handle column filtering
        />

    </div>
  )
}

export default CandidateJobSubmission
