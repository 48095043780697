import React, { useState, useEffect, useMemo } from "react";
// // import StepProgressBar from 'react-step-progress';
// import 'react-step-progress/dist/index.css';
// import "./CreateCandidate.css";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetCustomTemplateNameQuery,
  usePostCandidateMutation,
} from "../../Redux/API/atsSlice";
import { useGetCustomerTemplateByIdQuery } from "../../Redux/API/atsSlice";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import { showToast } from "../ToastContainer/ToastProvider";
import { Subtitles } from "lucide-react";
import usePrivilege from "../Privileges/Privileges";
import { readPdf } from "../../ResumeParser/read-pdf.ts";
import { groupTextItemsIntoLines } from "../../ResumeParser/group-text-items-into-lines.ts";
import { groupLinesIntoSections } from "../../ResumeParser/group-lines-into-sections.ts";
import { extractResumeFromSections } from "../../ResumeParser/extract-resume-from-sections/index.ts";
import "./CreateCandidate.css";
import createNew from '../../Assests/createNewIcon.svg'

const CreateCandidate = ({fileWanttobeParse}) => {
  const [fileNeedtobeParsed, setFile] = useState()

  const [DropdownChange, setDropDownChange] = useState("");
  const [dropdownValue, setDropdownValue] = useState([]);
  const [customInputs, setCustomInput] = useState([]);
  const [Remove, setRemove] = useState(false);
  const [formData, setFormData] = useState({
    departmentName: "",
    parentDepartment: "",
    departmentLead: "",
    attachment: null,
  });

  const isAuth = useSelector((state) => state.auth);
  const [params] = useSearchParams();

const privilege=usePrivilege('Candidate Create')
  //Get the department data,template name and layout data from RTK query
  // const { data: customLayoutNameData, isSuccess: CustomLayoutNameSuccess } =
  //   useGetCustomTemplateNameQuery({
  //     domain_name: isAuth?.user.domain_name,
  //     moduleName: params.get("type") ? params.get("type") : null,
  //   });


  const { data: customLayoutNameData, isSuccess: CustomLayoutNameSuccess } =
    useGetCustomTemplateNameQuery({
     domain_name: isAuth?.user.domain_name,
     moduleName: params.get("type")==='createCandidates' ? params.get("type") : params.get("type")==="parse-resume" && fileWanttobeParse? "createCandidates" :null,
    });
  //console.log("templatename",params.get("type")==='createCandidates' ? params.get("type") : params.get("type")==="parse-resume" && fileWanttobeParse? "createCandidates" :null)
  const [addNew, setaddNew] = useState({
    experience: "",
    education: "",
    address: "",
    certification: "",
  });

  const {
    data: customerLayoutIdData,
    isSuccess: CustomerLayoutIdSuccess,

    refetch: customerLayoutIdRefetch,
  } = useGetCustomerTemplateByIdQuery({
    domain_name: isAuth?.user.domain_name,
    templateId: DropdownChange || null,
  });

  

  useEffect(() => {
    if (customLayoutNameData?.status && customLayoutNameData?.message) {
      setDropdownValue(customLayoutNameData?.message || []);
      setDropDownChange(customLayoutNameData?.message[0]?.template_id)

    }
  }, [CustomLayoutNameSuccess]);

  useEffect(() => {
    customerLayoutIdRefetch();

    if (
      customerLayoutIdData?.status &&
      Array.isArray(customerLayoutIdData?.message)
    ) {
      setCustomInput(customerLayoutIdData.message);

      setaddNew((prevState) => {
        const updatedData = { ...prevState };

        customerLayoutIdData.message.forEach((item) => {
          if (item.section === "Experience") {
            updatedData.experience = item.fields;
          } else if (item.section === "Education") {
            updatedData.education = item.fields;
          } else if (item.section === "Address") {
            updatedData.address = item.fields;
          } else if (item.section === "Certifications") {
            updatedData.certification = item.fields;
          }
        });

      
        return updatedData;
      });
    } else {
      setCustomInput([]);
    }
  }, [CustomerLayoutIdSuccess, DropdownChange, customerLayoutIdData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "attachment") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [createCandidate] = usePostCandidateMutation();
   const handleListener = async () => {
      try {
        const data = {};
        customInputs &&
          customInputs.forEach((section, i) => {
            section?.fields.forEach((item) => {
              if (item?.datatype === "boolean") {
                data[`${item?.ColumnName}`] =
                  item?.valueOftheField === "true"
                    ? true
                    : item?.valueOftheField === "false"
                    ? false
                    : item?.valueOftheField === true
                    ? true
                    : false;
              } else if (item?.datatype === "int") {
                data[`${item?.ColumnName}`] = parseInt(item?.valueOftheField);
              } else {
                data[`${item?.ColumnName}`] = item?.valueOftheField;
              }
            });
          });
    
        try {
          const response = await createCandidate({
            domain_name: isAuth?.user.domain_name,
            created_by:`${isAuth?.user?.firstname} ${isAuth?.user?.lastname}`,
            user_id:isAuth?.user?.user_id,
            data: {
              data,
              customInputs,
            },
          });

          showToast(response.data.status, response.data.message);
          const clearFieldValues = () => {
            const updatedCustomInputs = customInputs.map((section) => {
              return {
                ...section,
                fields: section.fields.map((field) => {
                  // Reset the value of the field based on its type
                  // if (field?.typeOftheField === 'dropdownInput' || field?.typeOftheField === 'multiselectInput') {
                  //   return {
                  //     ...field,
                  //     valueOftheField: setDropDownChange(""),  // Or null if you prefer
                  //   };
                  // }
  
                  if (field?.typeOftheField === 'numberInput'|| field?.typeOftheField === 'textInput' || field?.typeOftheField === "editorInput") {
                    return {
                      ...field,
                      valueOftheField: null // Or set to 0 for numbers
                    };
                  }
                  
                  if (field?.typeOftheField === 'dateInput') {
                    return {
                      ...field,
                      valueOftheField: ""  // Or null, or reset to a specific default date if needed
                    };
                  }
                  
                  if (field?.typeOftheField === 'checkboxInput') {
                    return {
                      ...field,
                      valueOftheField: false  // Assuming false for checkboxes
                    };
                  }
                  
                  return field;
                }),
              };
            });
          
            setCustomInput(updatedCustomInputs);  
          };
          clearFieldValues();
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }
    };

  const handleCancel = () => {
    setFormData({
      departmentName: "",
      parentDepartment: "",
      departmentLead: "",
      attachment: null,
    });
  };
 
  const handleDropdownChange = (e) => {
    setDropDownChange(e.target.value);
  };
  const navigate = useNavigate();
  const handleEditClick = (e) => {
    if (DropdownChange && DropdownChange !== "0") {
      const isValidTemplate = dropdownValue.some(
        (item) => item.template_id === DropdownChange
      );

      if (isValidTemplate) {
        // window.location.href = `/ats/dashboard/?type=editDepartments&id=${DropdownChange}`;
        navigate(`/ats/dashboard/?type=editCandidates&id=${DropdownChange}`, {
          state: {
            moduleName: params.get("type") ? params.get("type") : null,
          },
        });
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const handleNewFields = (e, type) => {
    e.preventDefault();
    if (type === "Experience") {
      setCustomInput((prev) => {
        const arr = [...prev];
        const updatedArray = arr.map((item, i) =>
          item?.section === "Experience"
            ? { ...item, fields: [...item?.fields, ...addNew.experience] }
            : { ...item }
        );
        return updatedArray;
      });
    }
    if (type === "Address") {
      setCustomInput((prev) => {
        const arr = [...prev];
        const updatedArray = arr.map((item, i) =>
          item?.section === "Address"
            ? { ...item, fields: [...item?.fields, ...addNew.address] }
            : { ...item }
        );
        return updatedArray;
      });
    }
    if (type === "Education") {
      setCustomInput((prev) => {
        const arr = [...prev];
        const updatedArray = arr.map((item, i) =>
          item?.section === "Education"
            ? { ...item, fields: [...item?.fields, ...addNew.education] }
            : { ...item }
        );
        return updatedArray;
      });
    }
    if (type === "Certifications") {
      setCustomInput((prev) => {
        const arr = [...prev];
        const updatedArray = arr.map((item, i) =>
          item?.section === "Certifications"
            ? { ...item, fields: [...item?.fields, ...addNew.certification] }
            : { ...item }
        );
        return updatedArray;
      });
    }
  };

  const handlesubmit = async (e) => {
    // e.preventDefault()
    if (!fileWanttobeParse || !(fileWanttobeParse instanceof File)) {
      console.error("Invalid file", fileWanttobeParse);
      return;
    }

    if (fileWanttobeParse?.fileUrl) {
      URL.revokeObjectURL(fileWanttobeParse.fileUrl);
    }

    const { name, size } = fileWanttobeParse;
    const fileUrl = URL.handleObjectURL(fileWanttobeParse);



    // Proceed with parsing the file
    const updatedFile = { name, size, fileUrl };

    // If `readPdf` is an async function
    const respp = await readPdf(fileUrl);

    const lines = groupTextItemsIntoLines(respp || []);
    const sections = groupLinesIntoSections(lines);
    const resume = extractResumeFromSections(sections);
 

    const CandidateName = resume.profile.name
    // setCustomInput((prev) => {
    //     return prev.map((item) => ({
    //         ...item,
    //         fields: item.fields.map((ele) =>
    //             ele.label === "First Name"
    //                 ? { ...ele, valueOftheField: CandidateName.split(" ")[0] || "" }
    //             :ele.label==='Last Name'?
    //             {...ele,valueOftheField:CandidateName.split(" ")[1] || ""}
    //             :ele.label==='Email'?
    //             {...ele,valueOftheField:resume.profile.email}
    //             :ele.label==='Contact Number'?
    //             {...ele,valueOftheField:resume.profile.phone}
    //                 : ele
    //         ),
    //     }));
    // });

    setCustomInput((prev) => {
      return prev.map((item, i) => {
        if (item?.section === 'Education') {
          const eduFields = []
          const parsedEducation = resume.educations.map((ele, i) => ({ "institute_name": ele.school, "degree": ele.degree }))

          let k = 0;
          let index = 0;
          let count = 0;
          for (let y = 0; y <= resume.educations.length - 1; y++) {
            eduFields.push(...item?.fields)
          }

          while (k <= eduFields.length - 1) {
            if (count === 2) {
              count = 0;
              index++;
            }
            if (eduFields[k]?.label === "Institute Name") {
              eduFields[k] = {
                ...eduFields[k],
                valueOftheField: parsedEducation[index]?.institute_name
              }
              count++;
            } else if (eduFields[k]?.label === "Field Of Study") {
              eduFields[k] = {
                ...eduFields[k],
                valueOftheField: parsedEducation[index]?.degree
              }
              count++;
            }

            k++;
          }
          return {
            ...item,
            fields: eduFields.length===0?item?.fields:eduFields
          }
        }else if(item?.section==='Experience'){
          const expFields = []
          const parsedExper = resume.workExperiences.map((ele, i) => ({ "company": ele.company, "job_title": ele.jobTitle }))
    
          let k = 0;
          let index = 0;
          let count = 0;
          for (let y = 0; y <= resume.workExperiences.length - 1; y++) {
            expFields.push(...item?.fields)
          }
         
          while (k <= expFields.length - 1) {
            if (count === 2) {
              count = 0;
              index++;
            }
            if (expFields[k]?.label === "Job Title") {
              expFields[k] = {
                ...expFields[k],
                valueOftheField: parsedExper[index]?.job_title
              }
              count++;
            } else if (expFields[k]?.label === "Company Name") {
              expFields[k] = {
                ...expFields[k],
                valueOftheField: parsedExper[index]?.company
              }
              count++;
            }

            k++;
          }
          return {
            ...item,
            fields: expFields.length===0?item?.fields:expFields
          }
        } else {
          return {
            ...item,
            fields: item.fields.map((ele) =>
              ele.label === "First Name"
                ? { ...ele, valueOftheField: CandidateName.split(" ")[0] || "" }
                : ele.label === 'Last Name' ?
                  { ...ele, valueOftheField: CandidateName.split(" ")[1] || "" }
                  : ele.label === 'Email' ?
                    { ...ele, valueOftheField: resume.profile.email }
                    : ele.label === 'Contact Number' ?
                      { ...ele, valueOftheField: resume.profile.phone }
                      : ele
            ),
          }
        }
      })
    })


    // try{
    //   const res = await uploadResume({
    //     data:form
    //   })
    //   if(res?.data?.status){
    //     const name = res.data?.message?.personalInfo?.name
    //     const email = res.data?.message?.personalInfo?.email
    //     const phno = res.data?.message?.personalInfo?.phone
    //     setCustomInput((prev) => {
    //       return prev.map((item) => ({
    //           ...item,
    //           fields: item.fields.map((ele) =>
    //               ele.label === "First Name"
    //                   ? { ...ele, valueOftheField: name.split(" ")[0] || "" }
    //               :ele.label==='Last Name'?
    //               {...ele,valueOftheField:name.split(" ")[1] || ""}
    //               :ele.label==='Email'?
    //               {...ele,valueOftheField:email}
    //               :ele.label==='Contact Number'?
    //               {...ele,valueOftheField:phno}
    //                   : ele
    //           ),
    //       }));
    //   });
    //   }
    //   console.log("res",res)
    // }catch(err){

    // }
  }
  useEffect(()=>{
    if(fileWanttobeParse){
     handlesubmit()
    }
},[fileWanttobeParse])
  const [isEdit,setisEdit] = useState(false)
  return (
    // <div className="dept-form-container">
    //   <div className="dept-form-header">
    //   <input type="file"  onChange={(e) => setFile(e.target.files[0])} />
    //     <button onClick={handlesubmit}>Submit</button>
    //     <label htmlFor="template-dropdown" >Select Template:</label>

    //     <select
    //       id="template-dropdown"
    //       value={DropdownChange}
    //       onChange={(e) => {
    //         setDropDownChange(e.target.options[e.target.selectedIndex].value);
    //         console.log("gggg", e.target.options[e.target.selectedIndex].value);
    //       }}
    //       style={{ padding: "5px", width: "40%" }}
    //     >
    //       {dropdownValue &&
    //         [
    //           { template_name: "Choose an Template", template_id: 0 },
    //           ...dropdownValue,
    //         ].map((item, i) => {
    //           return (
    //             <option
    //               key={item?.template_id}
    //               label={item?.template_name}
    //               value={item?.template_id}
    //             />
    //           );
    //         })}
    //     </select>
    //     <a href={`/ats/dashboard/?type=editDepartments&id=${DropdownChange}`}>
    //       <button onClick={handleEditClick}>Edit</button>
    //     </a>
    //   </div>
    //   {customInputs &&
    //     customInputs.map((parent_item, parent_index) => {
    //       return (
    //         <div>
    //           <p style={{ fontWeight: "bold", fontSize: "15pt" }}>
    //             {parent_item?.section}
    //           </p>
    //           {parent_item?.section === "Experience" && (
    //             <button onClick={(e) => handleNewFields(e, "Experience")}>
    //               Add New Experience
    //             </button>
    //           )}
    //           {parent_item?.section === "Address" && (
    //             <button
               
    //               onClick={(e) => handleNewFields(e, "Address")}
    //             >
    //               Add New Address
    //             </button>
    //           )}
    //           {parent_item?.section === "Education" && (
    //             <button
                
    //               onClick={(e) => handleNewFields(e, "Education")}
    //             >
    //               Add New Education
    //             </button>
    //           )}
    //           {parent_item?.section === "Certifications" && (
    //             <button
    //               style={{ backgroundColor: "red", marginBottom: "10px" }}
    //               onClick={(e) => handleNewFields(e, "Certifications")}
    //             >
    //               Add New Certifications
    //             </button>
    //           )}

    //           <div>
    //             {parent_item?.fields &&
    //               parent_item?.fields.map((item, i) => {
    //                 return (
    //                   <div>
    //                     <DynamicTextInput
    //                         className="border-b-2 focus:outline-none flex-1 mr-2"
    //                         key={i}
    //                         parentInd={parent_index}
    //                         remove={Remove}
    //                         onChangeRemove={setRemove}
    //                         index={i}
    //                         {...item}
    //                         value={customInputs}
    //                         customInputs={customInputs}
    //                         onChangeCustomInput={setCustomInput}
    //                         onChangeValue={setCustomInput}
    //                       />
    //                   </div>
    //                 );
    //               })}
    //           </div>
    //         </div>
    //       );
    //     })}
    //   <div className="dept-header-actions">
    //     {/* <button className="dept-button save-new">Save and New</button> */}
    //     <button className="dept-button save"  onClick={() => {
    //           handleListener();
    //         }}
    //         style={
    //           privilege.includes(isAuth?.user?.user_id)
    //             ? { display: "block" }
    //             : { display: "none" }
    //         }
    //         >
    //       Save
    //     </button>
    //     <button className="dept-button cancel" onClick={handleCancel}>
    //       Cancel
    //     </button>
    //   </div>
    // </div>
    <>
    <div className="dept-form-container">
      <div className="dept-form-header">
        <label htmlFor="template-dropdown">Select Template:</label>
        <select
          id="template-dropdown"
          value={DropdownChange}
          onChange={(e) => {
            setDropDownChange(e.target.options[e.target.selectedIndex].value);
            //console.log("gggg", e.target.options[e.target.selectedIndex].value);
          }}
          style={{ padding: "5px", width: "40%" }}
        >
          {dropdownValue &&
            [
              { template_name: "Choose an Template", template_id: 0 },
              ...dropdownValue,
            ].map((item, i) => {
              return (
                <option
                  key={item?.template_id}
                  label={item?.template_name}
                  value={item?.template_id}
                />
              );
            })}
        </select>
        <a href={`/ats/dashboard/?type=editDepartments&id=${DropdownChange}`}>
          <button onClick={handleEditClick}>Edit</button>
        </a>
      </div>
      {customInputs &&
        customInputs.map((parent_item, parent_index) => {
          return (
            <div>
              <div className="candidate_section_name">
              <p style={{ fontWeight: "bold", fontSize: "15pt" }}>
                {parent_item?.section}
              </p>
              {parent_item?.section === "Experience" && (
                <button
                className="create-candidate-addbtn" style={{height:30}}  onClick={(e) => handleNewFields(e, "Experience")}>
                  Add New Experience <img src={createNew} className="create-candidate-btnicon" alt='icon'/>
                </button>
              )}
              {parent_item?.section === "Address" && (
                <button
                className="create-candidate-addbtn"
                style={{height:30}}  
                  onClick={(e) => handleNewFields(e, "Address")}
                >
                  Add New Address <img src={createNew} alt='icon' />
                </button>
              )}
              {parent_item?.section === "Education" && (
                <button
                className="create-candidate-addbtn"
                style={{height:30}}  
                  onClick={(e) => handleNewFields(e, "Education")}
                >
                  Add New Education <img src={createNew} alt='icon' />
                </button>
              )}
              {parent_item?.section === "Certifications" && (
                <button
                className="create-candidate-addbtn"

                style={{height:30}}  
            //      style={{ marginBottom: "10px" }}
                  onClick={(e) => handleNewFields(e, "Certifications")}
                >
                  Add New Certifications <img src={createNew} alt='icon' />
                </button>
              )}
              </div>
              <div className="line"></div>
              <div className="candidate_alignment" style={parent_item?.section==='Basic Info'?{marginLeft:"25%"}:{}}>
                {parent_item?.fields &&
                  parent_item?.fields.map((item, i) => {
                    return (
                      <div>
                        {item?.label==="Profile Pic"?
                            <div style={parent_item?.section==='Basic Info'?{position:'absolute',left:20,top:300}:{}} className="profile-pic">
                              <div className="circle"><div className="head"></div><div className="human-body"></div></div>
                              {isEdit &&    <DynamicTextInput
                          className="border-b-2 focus:outline-none flex-1 mr-2"
                          key={i}
                          parentInd={parent_index}
                          remove={Remove}
                          onChangeRemove={setRemove}
                          index={i}
                          {...item}
                          value={customInputs}
                          customInputs={customInputs}
                          onChangeCustomInput={setCustomInput}
                          onChangeValue={setCustomInput}
                        />}            
                            </div>:
                            <div style={parent_item?.section==='Basic Info'?{width:'300px'}:{width:'300px'}}>
                            <DynamicTextInput
                            className="border-b-2 focus:outline-none flex-1 mr-2"
                            key={i}
                            parentInd={parent_index}
                            remove={Remove}
                            onChangeRemove={setRemove}
                            index={i}
                            {...item}
                            value={customInputs}
                            customInputs={customInputs}
                            onChangeCustomInput={setCustomInput}
                            onChangeValue={setCustomInput}
                          
                          />
                          </div>
                        } 
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      
    </div>
    <div className="create-candidate-bottom-section">
        {/* <button className="dept-button save-new">Save and New</button> */}
        <button className="create-candidate-bottom-section-save" onClick={() => {
          handleListener();
        }}
          style={
            privilege.includes(isAuth?.user?.user_id)
              ? { display: "block" }
              : { display: "none" }
          }
        >
          Save
        </button>
        <button className="create-candidate-bottom-section-cancel" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default CreateCandidate;