import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetCandidateSkillQuery,
  useGetCandidateUpdateByIdQuery,
  useGetCustomerJobUpdateByIdQuery,
  usePosCustomerJobUpdateByIdMutation,
  useUpdateCandidateMutation,
  useUpdateTemplateByIdMutation,
} from "../../Redux/API/atsSlice";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { domAnimation } from "framer-motion";
import usePrivilege from "../Privileges/Privileges";

function EditCandidate() {
  const { candidate_id } = useParams();
  const MovableInput = useRef();
  const isAuth = useSelector((state) => state.auth);
  const [customInputs, setCustomInput] = useState([]);
  const [EditJob] = usePosCustomerJobUpdateByIdMutation();
  const [Remove, setRemove] = useState(false);
    const [addNew, setaddNew] = useState({
      experience: "",
      education: "",
      address: "",
      certification: "",
    });
  

  const [params] = useSearchParams();

  //  console.log(id)
  const { data, isSuccess, refetch } = useGetCandidateUpdateByIdQuery(
    {
      domain_name: isAuth?.user?.domain_name,
      candidate_id: candidate_id,
    },
    {
      skip: !candidate_id,
    }
  );
  // console.log("data", data);
  const privilege=usePrivilege('Candidate Update')
  const { data: CandidateSkill, refetch: CandidateSkillRefetch } =
    useGetCandidateSkillQuery({
      domain_name: isAuth?.user?.domain_name,
      candidate_id: candidate_id,
    });
  console.log("Skil", CandidateSkill);
  useEffect(() => {
    refetch();
    if (data?.status) {
      setCustomInput(data?.message || []);
      setCustomInput((prev) => {
        return prev.map((item) =>
          item?.section === "Skills"
            ? {
                ...item,
                fields: item?.fields.map((ele) => ({
                  ...ele,
                  valueOftheField: CandidateSkill?.status
                    ? JSON.stringify(CandidateSkill?.message)
                    : [],
                })),
              }
            : item
        );
      });
    }
  }, [isSuccess, candidate_id]);

  const handleNewFields = (e, type, index) => {
    e.preventDefault();
    setCustomInput((prev) => {
      return prev.map((item) => {
        if (item.section === type) {
          const newfields = item?.fields?.slice(0,index).map((ele,i)=>({...ele,valueOftheField:"",element_id:""}))
          return {
            ...item,
            fields: [...item.fields,...newfields ], 
          };
        }
        return item;
      });
    });
  };


  const [updateCandidate] = useUpdateCandidateMutation();
  const handleListener = async () => {
    try {
      const response = await updateCandidate({
        domain_name: isAuth?.user?.domain_name,
        candidate_id: candidate_id,
        data: customInputs,
        user_id:isAuth?.user?.user_id
      });
      console.log("Response==>>", response);
    } catch (err) {
      console.log(err)
    }
  };
console.log("customeinputs", customInputs);



  return (
    <div>
      <div
        className="create-job-container"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {/* <EditCreateJobLayout setCustomInput={setCustomInput} customInputs={customInputs} handleInput={handleInput} setHandleInput={setHandleInput} /> */}

        <div>
          <button
            onClick={handleListener}
            disabled={!privilege.includes(isAuth?.user?.user_id)} // Disable if user is unauthorized
            style={{
              cursor: privilege.includes(isAuth?.user?.user_id)
                ? "pointer"
                : "not-allowed",
              opacity: privilege.includes(isAuth?.user?.user_id) ? 1 : 0.6,
            }}
            className="edit-save-changes-button"
          >
            Update Candidate
          </button>
          <button className="edit-cancel-button">
            <a href="/ats/dashboard/?type=candidates">Cancel</a>
          </button>
          <div>
            {customInputs.map((parent_item, parent_index) => (
              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "15pt",
                    outline: "none",
                    border: "none",
                  }}
                >
                  {parent_item?.section}
                </p>
                {/* <p style={{ fontWeight: "bold", fontSize: "15pt" }}>
                {parent_item?.section}
              </p> */}
              {parent_item?.section === "Experience" && (
                <button onClick={(e) => handleNewFields(e, "Experience",6)}>
                  Add New Experience
                </button>
              )}
              {parent_item?.section === "Address" && (
                <button
               
                  onClick={(e) => handleNewFields(e, "Address",6)}
                >
                  Add New Address
                </button>
              )}
              {parent_item?.section === "Education" && (
                <button
                
                  onClick={(e) => handleNewFields(e, "Education",5)}
                >
                  Add New Education
                </button>
              )}
              {parent_item?.section === "Certifications" && (
                <button
                  style={{ marginBottom: "10px" }}
                  onClick={(e) => handleNewFields(e, "Certifications",3)}
                >
                  Add New Certifications
                </button>
              )}
                <div>
                  {parent_item?.fields &&
                    parent_item?.fields.map((item, i) => (
                      <div className="bg-white p-4 rounded-lg shadow-md mb-3 flex items-center justify-between">
                        <DynamicTextInput
                          //   className={`border-b-2 focus:outline-none flex-1 mr-2 ${
                          //     isEditor ? "editor-class" : ""
                          //   }`}
                          parentInd={parent_index}
                          //   remove={Remove}
                          //   onChangeRemove={setRemove}
                          index={i}
                          {...item}
                          value={customInputs}
                          customInputs={customInputs}
                          onChangeCustomInput={setCustomInput}
                          onChangeValue={setCustomInput}
                        />
                        {/* <DynamicTextInput className='border-b-2 focus:outline-none flex-1 mr-2' key={i} parentInd={parent_index} remove={Remove} onChangeRemove={setRemove} index={i} {...item} value={customInputs} customInputs={customInputs} onChangeCustomInput={setCustomInput} onChangeValue={setCustomInput}
                            /> */}
                          {parent_item?.section==='Experience' && (i+1)%6==0 && (
                            <button>Delete</button>
                          )
                          }
                          {
                            parent_item?.section==='Address' && (i+1)%6==0 && (
                              <button>Delete</button>
                            )
                          }
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCandidate;
