import React, { useState } from "react";
import Modal from "react-modal";
import Preview from "../DocumentPreview/Preview";
import CreateCandidate from "../CreateCandidate/CreateCandidate";


const ParseResume = () =>{
    const [file,setfile] = useState()
    return(
          <div style={{marginTop:100}}>
            <input type="file" onChange={(e)=>{setfile(e.target.files[0])}}></input>
            {file &&
                <div style={{width:'100vw',height:'100vh',maxHeight:'100vh',minHeight:'100vh',display:'flex'}}>
                <div style={{width:'50%'}}>
                <Preview filetobePreview={file} />
                </div>
                <div style={{width:'50%'}}>
                <CreateCandidate fileWanttobeParse={file}/>
                </div>
                </div>
            }   
          </div>
    )
}


export default ParseResume