import React, { useEffect, useState } from "react";
import { useGetSettingsQuery, useUpdateSettingsMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import './DashBoardSettings.css'
import { showToast } from "../../../ToastContainer/ToastProvider";


const DashBoardSettings = () =>{
    const isAuth = useSelector((state) => state.auth);
    const [setting,setSetting] = useState()

    const {
        data, isSuccess, refetch
    } = useGetSettingsQuery({
        domain_name:isAuth?.user?.domain_name
    })
    // console.log("Data",data)
    useEffect(()=>{
        if(data?.status){
            setSetting(data?.message)
        }
    },[isSuccess])
    const [update] = useUpdateSettingsMutation()
    const handleUpdateSettings = async (req,res)=>{
        try{
            const res = await update({
                domain_name:isAuth?.user?.domain_name,
                data:setting
            })
            if(res)
                  showToast(res?.data?.status,res?.data?.message)
            // console.log("res",res)
        }catch(err){
            showToast(err?.data?.status,err?.data?.message)
        }
    }

    return(
        <div>
        <div className="setting-dashboard-control-container">
            <div className="setting-dashboard-control-header">
                <p>Dashboard</p>
                <p>Add</p>
            </div>
            <div className="setting-dashboard-control-list">
                {setting && setting.map((item, i) => (
                    <div key={i} className="setting-dashboard-control-item">
                        <p className="setting-dashboard-control-label">{item?.label}</p>
                        <input 
                            type={item?.type} 
                            checked={item?.is_enabled} 
                            value={item?.is_enabled} 
                            onChange={(e) => {
                                setSetting((prev) => 
                                    prev.map((val, idx) => 
                                        idx === i ? { ...val, is_enabled: parseInt(val.is_enabled) === 1 ? 0 : 1 } : val
                                    )
                                );
                            }} 
                        />
                    </div>
                ))}
            </div>
        </div>
        <div className="setting-dashboard-control-footer">
            <button className="setting-dashboard-control-cancel-btn">Cancel</button>
            <button className="setting-dashboard-control-save-btn" onClick={handleUpdateSettings}>Save Changes</button>
        </div>
    </div>
    
    
    )
}


export default DashBoardSettings