import React, { useEffect, useState } from "react";
import { List, arrayMove, arrayRemove } from "react-movable";
import WideMovable from "../LargeMovable/LargeMovable";
import NotWideMovable from "../SmallMovable/SmallMovable";
import AtsDashBoardHeader from "../AtsDashBoardHeader/AtsDashBoardHeader";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateJob from "../CreateJob/CreateJob";
import JobOpening from "../JobOpening/JobOpening";
import Settings from "../Settings/Settings";
import EditCreateJobLayout from "../CreateJob/EditCreateJobLayout";
import DepartmentData from "../CreateDepartment/DepartmentData";
import  Departments from '../Departments/Departments'
import ChangePrefix from "../Settings/SubSettings/ChangePrefix/ChangePrefix";
import EmbededCode from "../Settings/SubSettings/EmbededCode/EmbededCode";
import { RotateCcw } from "lucide-react";
import { EllipsisVertical } from "lucide-react";
import { X } from "lucide-react";
import "./DashBoard.css";
import AtsDashBoardSubHeader from "../AtsDashBoardSubHeader/AtsDashBoardSubHeader";
import WinspireHeader from "../AtsDashBoardHeader/WinspireHeader";
import JDHeader from "../JobDetailHeader/JDHeader";
import CreateCandidate from "../CreateCandidate/CreateCandidate";
import Candidates from "../Candidates/Candidates";
import CandidateDetail from "../Candidates/CandidateDetail";
import { color } from "../../Common/theme/theme";
import CreateInterview from "../CreateInterview/CreateInterview";
import Interview from "../Interviews/Interview";
import DashBoardContent from "../DashBoardContent/DashBoardContent";
import usePrivilege from "../Privileges/Privileges";
import Role from "../Settings/SubSettings/Roles/Roles";
import CreateUser from "../Settings/SubSettings/UserCreation/CreateUser";
import CreateGroups from "../Settings/SubSettings/Groups/CreateGroups";
import Reportees from "../Settings/SubSettings/Reportees/Reportees";
import PersonalSettingsForm from "../Settings/SubSettings/PersonalSetting/PersonalSetting";
import CandidateSettings from "../Settings/SubSettings/ModuleSettings/CandidateSetting";
import LoginSettings from "../Settings/SubSettings/ModuleSettings/LoginSettings";
import DashBoardSettings from "../Settings/SubSettings/ModuleSettings/DashBoardSettings";
import ParseResume from "../Candidates/ParseResume";


// keerthan 04-jan-2025 implemented movable component
const deletebuttonStyles = {
  border: "none",
  margin: 0,
  padding: 0,
  width: "auto",
  overflow: "visible",
  cursor: "pointer",
  background: "transparent",
  flex: "1",
};

const RemovableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x-circle"
  >
    <title>Remove</title>
    <circle cx="12" cy="12" r="10" />
    <line x1="15" y1="9" x2="9" y2="15" />
    <line x1="9" y1="9" x2="15" y2="15" />
  </svg>
);

const MovableComponent = () => {
  const theme = localStorage.getItem("theme");

  const refreshItem = (index) => {
    setItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index
          ? {
              ...item,
              key: Date.now(),
              label:
                item.size === "big" ? (
                  <WideMovable key={Date.now()} theme={theme} />
                ) : (
                  <NotWideMovable key={Date.now()} theme={theme} />
                ),
              isRefreshing: true,
            }
          : item
      )
    );

    // Remove highlight after a short delay
    setTimeout(() => {
      setItems((prevItems) =>
        prevItems.map((item, i) =>
          i === index ? { ...item, isRefreshing: false } : item
        )
      );
    }, 500);
  };

  // useEffect(()=>{
  //   refreshItem()
  // })

  // Step 1: Initialize the state with a list of items and their sizes.
  const [items, setItems] = useState([
    {
      id: "1",
      size: "big",
      label: <WideMovable theme={theme} />,
      key: 1,
      header: "Dashboard Overview",
    },
    {
      id: "2",
      size: "small",
      label: <NotWideMovable theme={theme} />,
      key: 2,
      header: "Recent Job Activity",
    },
    {
      id: "3",
      size: "small",
      label: <NotWideMovable theme={theme} />,
      key: 3,
      header: "Active Job Lists",
    },
    {
      id: "4",
      size: "big",
      label: <WideMovable theme={theme} />,
      key: 4,
      header: "User Statistics",
    },
    {
      id: "5",
      size: "small",
      label: <NotWideMovable theme={theme} />,
      key: 5,
      header: "Submission Activities",
    },
    {
      id: "6",
      size: "small",
      label: <NotWideMovable theme={theme} />,
      key: 6,
      header: "Calender (Active sessions)",
    },
  ]);
  const [searchparam] = useSearchParams();
  const type = searchparam.get("type");
  return (
    <div style={{backgroundColor:"red"}}>
      {/* <AtsDashBoardHeader/> */}
      {type === "job_openings" ? (
        <JobOpening />
      ) : (
        <div
          style={{
            // maxWidth: "800px",
            margin: "0px auto",
            backgroundColor: "white",
            padding: "0 3em",
          }}
        >
          <List
            values={items}
            onChange={({ oldIndex, newIndex }) => {
              setItems((prevItems) => arrayMove(prevItems, oldIndex, newIndex));
            }}
            renderList={({ children, props, isDragged }) => (
              <ul
                {...props}
                // className="movable-list"
                style={{
                  color: theme === "dark" ? "black" : "white",
                  padding: 0,
                  cursor: isDragged ? "grabbing" : undefined,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  backgroundColor: theme === "dark" ? "black" : "white",
                }}
              >
                {children}
              </ul>
            )}
            renderItem={({ value, props, isDragged, isSelected, index }) => (
              <li
                {...props}
                key={value.key}
                className="movable-item"
                style={{
                  ...props.style,
                  padding: "1.5em",
                  margin: "0.5em 0em",
                  listStyleType: "none",
                  cursor: isDragged ? "grabbing" : "grab",
                  border: "1px solid #CCC",
                  color: theme === "dark" ? "white" : "black",
                  borderRadius: "5px",
                  backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF",
                  width: value.size === "big" ? "100vw" : "30vw",
                  minHeight: "50vh",
                  backgroundColor: value.isRefreshing ? "#ffd700" : "#FFF",
                  transition: "background-color 0.3s ease",
                }}
              >
                <div
                  style={{
                    
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "40px", 
                  }}
                >
                  <h2 style={{ color: color.fullBlack, fontSize: "1.5em", margin: 0 }}>
                    {value.header}
                  </h2>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <button
                      style={{
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => refreshItem(index)}
                      title="Refresh"
                    >
                      <RotateCcw color={color.fullBlack} size={12}/> 
                    </button>
                    <button
                      style={{
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                      }}
                    >
                      <EllipsisVertical color={color.fullBlack} size={12}/>
                    </button>
                    <button
                      style={{
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                      }}
                      onClick={() =>
                        setItems((prevItems) =>
                          prevItems.filter((_, i) => i !== index)
                        )
                      }
                    >
                      <X color={color.fullBlack}  size={12}/>
                    </button>
                  </div>
                </div>

                {value.label}
                {index === 0 && <span data-movable-handle />}
              </li>
            )}
          />
        </div>
      )}
    </div>
  );
};
// According to the type from the params components will be rendered
const DashBoard = () => {
  const [params] = useSearchParams();
  const settingsSubType = params.get("subtype");
  const type = params.get("type");
   const isAccessCreateJob=usePrivilege('Job Create');
   const isAccessViewJob=usePrivilege('Job')
   const isAccessCreateDepartment=usePrivilege('Department Create')
   const isAccessCreateCandidate=usePrivilege('Candidate Create');
   const isAccessCreateInterview=usePrivilege('Interview Create');
   const isSettings = usePrivilege("Settings")
  //  console.log("eqe",isAccessCreateJob)
    const isAuth = useSelector((state) => state.auth);

  if (type === "settings" && settingsSubType) {
    switch (settingsSubType) {
      case "change-prefix":
        return (
          <>
            <AtsDashBoardHeader />
            <ChangePrefix />;
          </>
        );
      case "edit-style":
        return (
          <>
            <AtsDashBoardHeader />
            <EmbededCode />
          </>
        );
        case "role":
          return(
           <>
           <AtsDashBoardHeader/>
           <Role/>
           </>
          )
       case "user-management":
          return (
           <>
           <AtsDashBoardHeader/>
           <CreateUser/>
           </>
          )
  
       case "create-groups":
          return(
           <>
           <AtsDashBoardHeader/>
           <CreateGroups/>
           </>
          )
       case "hierachy":
          return(
           <>
           <AtsDashBoardHeader/>
           <Reportees/>
           </>
          )
          case "personal-settings":
            return(
              <>
              <AtsDashBoardHeader/>
              <PersonalSettingsForm/>
              </>
            )
            case "candidate-settings":
        return(
          <>
          {
            isAuth?.user?.role_id==="WR0004" && <>  <AtsDashBoardHeader />
          <CandidateSettings/></>
          }
          </>
        )
      case "login-settings":
        return(
          <>
          {
            isAuth?.user?.role_id==="WR0004" && <>  <AtsDashBoardHeader />
          <LoginSettings/></>
          }
          </>
        )
      case "dashboard-settings":
        return(
            <>
          {
            isAuth?.user?.role_name==="WR0004" && <>  <AtsDashBoardHeader />
          <DashBoardSettings/></>
          }
          </>
        )
      default:
        return <p>Invalid settings option</p>;
    }
  }

switch (params.get("type")) {
    case "createJob":
      return (
        <>{
          isAccessCreateJob.includes(isAuth?.user?.user_id) &&
          <>
          <AtsDashBoardHeader />
          <CreateJob />
          </>
        } 
        </>
      );
    case "job_openings":
      return (
        <>
          <AtsDashBoardHeader />
          <div style={{marginTop:'20vh'}}>
          <JobOpening />  
          </div>
        </>
      );
      case "interviews":
      return (
        <>
          <AtsDashBoardHeader />
          <div style={{marginTop:'20vh'}}>
          <Interview/>
          </div>
        </>
      );
      case "candidates":
        return (
          <>
            <AtsDashBoardHeader />
            <div style={{marginTop:'20vh'}}>
            <Candidates />
            </div>
          </>
        );
    case "settings":
      return (
        <>
  {isSettings.includes(isAuth?.user?.user_id) && (
    <>
      <AtsDashBoardHeader />
      <div style={{ marginTop: '20vh' }}>
        <Settings />
      </div>
    </>
  )}
</>
      );
    case "editLayout":
      return (
        <>
          <AtsDashBoardHeader />
          <EditCreateJobLayout />
        </>
      );

    case "createDepartments":
      return (
        <>{
          isAccessCreateDepartment.includes(isAuth?.user?.user_id) &&
          <>
          <AtsDashBoardHeader />
          <DepartmentData />
          </>
        } 
        </>
      );

      case "createInterviews":
        return (
          <>{
            isAccessCreateInterview.includes(isAuth?.user?.user_id) &&
            <>
            <AtsDashBoardHeader />
            <CreateInterview />
            </>
          } 
          </>
        );
      case "createCandidates":
      return (
        <>
          <AtsDashBoardHeader />
          <CreateCandidate />
        </>
      );

      case "createCandidates":
      return (
        <>{
          isAccessCreateCandidate.includes(isAuth?.user?.user_id) &&
          <>
          <AtsDashBoardHeader />
          <CreateCandidate />
          </>
        } 
        </>
      );
    case "editDepartments":
      return (
        <>
          <AtsDashBoardHeader />
          <EditCreateJobLayout />
        </>
      );
    
    case "editCandidates":
      return(
        <>
         <AtsDashBoardHeader />
         <EditCreateJobLayout />
        </>
      )
    case "department":
      return (
        <>
          <AtsDashBoardHeader />
          <Departments />
        </>
      );

      case "parse-resume":
        return(
         <>
         <AtsDashBoardHeader />
         <ParseResume/>
         </>
        )

    // case "candidatesDetails":
    //   return(
    //     <>
    //     <AtsDashBoardHeader/>
    //     <CandidateDetail/>
    //     </>
    //   )
    default:
      return (
        <>
          <AtsDashBoardHeader />
          <AtsDashBoardSubHeader/>
          {/* <MovableComponent /> */}
          <DashBoardContent/>
        </>
      );
  }
};

export default DashBoard;
