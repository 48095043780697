import React, { useEffect, useState } from "react";
import {
  useCreateRoleMutation,
  useGetPrivilegeTypeQuery,
  useGetRoleQuery,
  useUpdatePrivilegesMutation,
} from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import "./Role.css";
import { FaUserEdit } from "react-icons/fa";
import { showToast } from "../../../ToastContainer/ToastProvider";

const Role = () => {
  const isAuth = useSelector((state) => state.auth);
  const [showForm, setShowForm] = useState(false);
  const [showRolePopup, setShowRolePopup] = useState(false);

  const [input, setInput] = useState({
    role_name: "",
    desc: "",
  });
  const [DropDown, setdropdown] = useState();
  const {
    data: PrivilegeType,
    isSuccess: PrivilegesSuccess,
    refetch: PrivilegeRefetch,
  } = useGetPrivilegeTypeQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  // console.log("getprivilege", PrivilegeType);

  useEffect(() => {
    if (PrivilegeType?.status) {
      setdropdown(PrivilegeType?.message || []);
    }
  }, [PrivilegesSuccess]);
  const [title, settitle] = useState([
    {
      id: "is_job",
      name: "Job",
      type: "checkbox",
      input: "",
      module: "Job",
    },
    {
      id: "job_create",
      name: "Job Create",
      type: "checkbox",
      input: "",
      module: "Job",
    },
    {
      id: "job_read",
      name: "Job Read",
      type: "dropdown",
      input: "",
      module: "Job",
    },
    {
      id: "job_update",
      name: "Job Update",
      type: "dropdown",
      input: "",
      module: "Job",
    },
    {
      id: "job_delete",
      name: "Job Delete",
      type: "dropdown",
      input: "",
      module: "Job",
    },
    // {
    //     id: "is_dept",
    //     name: "Department",
    //     type: "checkbox",
    //     input: ""
    // },
    // {
    //     id: "dept_create",
    //     name: "Department Create",
    //     type: "checkbox",
    //     input: ""
    // }, {
    //     id: "dept_read",
    //     name: "Department Read",
    //     type: 'dropdown',
    //     input: ''
    // }, {
    //     id: "dept_update",
    //     name: 'Department Update',
    //     type: 'dropdown',
    //     input: ''
    // }, {
    //     id: "dept_delete",
    //     name: 'Department Delete',
    //     type: 'dropdown',
    //     input: ""
    // },
    {
      id: "is_candidate",
      name: "Candidate",
      type: "checkbox",
      input: "",
      module: "Candidate",
    },
    {
      id: "candidate_create",
      name: "Candidate Create",
      type: "checkbox",
      input: "",
      module: "Candidate",
    },
    {
      id: "candidate_read",
      name: "Candidate Read",
      type: "dropdown",
      input: "",
      module: "Candidate",
    },
    {
      id: "candidate_update",
      name: "Candidate Update",
      type: "dropdown",
      input: "",
      module: "Candidate",
    },
    {
      id: "candidate_delete",
      name: "Candidate Delete",
      type: "dropdown",
      input: "",
      module: "Candidate",
    },
    {
      id: "is_interview",
      name: "Interview",
      type: "checkbox",
      input: "",
      module: "Interview",
    },
    {
      id: "interview_create",
      name: "Interview Create",
      type: "checkbox",
      input: "",
      module: "Interview",
    },
    {
      id: "interview_read",
      name: "Interview Read",
      type: "dropdown",
      input: "",
      module: "Interview",
    },
    {
      id: "interview_update",
      name: "Interview Update",
      type: "dropdown",
      input: "",
      module: "Interview",
    },
    {
      id: "interview_delete",
      name: "Interview Delete",
      type: "dropdown",
      input: "",
      module: "Interview",
    },
    {
      id: "is_settings",
      name: "Settings",
      type: "checkbox",
      input: "",
      module: "Setting",
    },
  ]);

  const [heading, setheading] = useState([
    {
      name: "Enabled",
      type: "checkbox",
    },
    {
      name: "Create",
      type: "checkbox",
    },
    {
      name: "View",
      type: "dropdown",
    },
    {
      name: "Edit",
      type: "dropdown",
    },
    {
      name: "Delete",
      type: "dropdown",
    },
  ]);
  const [selectedRole, setSelectedRole] = useState("Select Role");
  const [moduleData, setModuleData] = useState([
    {
      label: "Job",
      value: title?.filter((item, i) => item.module === "Job"),
      role_name: selectedRole || "",
    },
    {
      label: "Candidate",
      value: title?.filter((item, i) => item?.module === "Candidate"),
      role_name: selectedRole || "",
    },
    {
      label: "Interview",
      value: title?.filter((item, i) => item?.module === "Interview"),
      role_name: selectedRole || "",
    },
    {
      label: "Setting",
      value: title?.filter((item, i) => item?.module === "Setting"),
      role_name: selectedRole || "",
    },
  ]);

  useEffect(() => {
    setModuleData((prev) => {
      const result = [...prev];
      return result.map((item, i) => ({ ...item, role_name: selectedRole }));
    });
  }, [selectedRole]);

  const [roleData, setroleData] = useState([]);

  const { data, refetch, isSuccess } = useGetRoleQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  // console.log("roleData", data);

  //   useEffect(() => {
  //     if (data?.status) {
  //       setroleData(data?.message || []);
  //     }
  //   }, [isSuccess]);
  useEffect(() => {
    if (isSuccess && data?.status) {
      setroleData(data?.message || []);

      if (data?.message?.length > 0) {
        setSelectedRole(data.message[0]?.role_name);
      }
    }
  }, [isSuccess, data]);
  const [createRole] = useCreateRoleMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        role_name: input.role_name,
        role_desc: input.desc,
        created_by: isAuth?.user?.firstname,
      };
      const response = await createRole({
        domain_name: isAuth?.user?.domain_name,
        data: payload,
      });
      // console.log(response)
      showToast(response.data?.status, response?.data?.message);
    } catch (err) {}
  };
  const [updateRole] = useUpdatePrivilegesMutation();
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await updateRole({
        domain_name: isAuth?.user?.domain_name,
        data: roleData,
      });
      if(response)
        showToast(response.data.status,response.data.message)
      // console.log("Res", response);
    } catch (err) {}
  };
  // console.log("module", moduleData);
  return (
    <>
    <h3>Change Role</h3>
    <div
      className="Access-role-main-container"
      style={{ width: "1000px", position: "relative" }}
    >
      {/* <input type="text" placeholder="Enter the Role Name" onChange={(e) => setInput({ ...input, role_name: e.target.value })} />
            <textarea 
            className="Access-role-create-textarea"
            placeholder="Enter the Role desc" style={{height:'10%'}} rows={10} onChange={(e) => setInput({ ...input, desc: e.target.value })}></textarea>
            <button onClick={(e) => {
                handleSubmit(e)
            }}>Create</button> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="Access-role-selection">
          <span className="spantag-selected-role">Role:</span>
          <button
            className="Access-select-role-btn "
            onClick={() => setShowRolePopup(true)}
          >
            {selectedRole}
            {/* <FaUserEdit size={20} /> */}
          </button>
        </div>
        {showRolePopup && (
          <div className="Access-role-popup-overlay">
            <div className="Access-role-popup">
              <h5 className="Access-role-popup-title">Select a Role</h5>
              <ul className="Access-role-popup-list">
                {roleData.map((item) => (
                  <li
                    key={item.id}
                    className={`Access-role-popup-item ${
                      selectedRole === item.role_name ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedRole(item.role_name);
                      setShowRolePopup(false);
                    }}
                  >
                    {item.role_name}
                  </li>
                ))}
              </ul>
              <button
                className="Access-role-popup-close"
                onClick={() => setShowRolePopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
        <div className="Access-role-create-con">
          <button
            className="Access-role-create-button"
            onClick={() => setShowForm(true)}
          >
            Add Role <FaUserEdit className="setting-create-group-icon" />
          </button>

          {showForm && (
            <div className={`hidden-form show-form`}>
              <div className="Access-role-modal-header">
                Add Role
                <button onClick={() => setShowForm(false)}>✖</button>
              </div>
              <div className="Access-role-modal-body">
                <label>Enter Role Name*</label>
                <input
                  type="text"
                  className="Access-role-input-field"
                  placeholder="Enter Role Name"
                  onChange={(e) =>
                    setInput({ ...input, role_name: e.target.value })
                  }
                />

                <label>Description</label>
                <textarea
                  className="Access-role-textarea-field"
                  placeholder="Enter Description"
                  rows={10}
                  onChange={(e) => setInput({ ...input, desc: e.target.value })}
                ></textarea>
              </div>
              <div className="Access-role-modal-footer">
                <button
                  className="Access-role-cancel-button"
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </button>
                <button
                  className="Access-role-save-button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Create Role
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="Access-role-table-container"
        style={{ overflowX: "scroll", overflow: "auto", marginTop: "3%" }}
      >
        <table
          className="Access-role-table"
        //   border={1}
          cellSpacing={2}
          style={{ width: "100%", minWidth: "800px" }}
        >
          <thead>
            <tr style={{ columnGap: 3 }}>
              <th>Modules</th>
              {heading.map((item, i) => {
                return <th>{item?.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {moduleData &&
              moduleData.map((item, i) => {
                return (
                  <tr>
                    <td>{item.label}</td>
                    {item?.value.map((ele, i) => {
                      if (ele?.type === "dropdown") {
                        return (
                          <td style={{ alignSelf: "center" }}>
                            <select
                              value={
                                roleData.find(
                                  (items, i) =>
                                    items?.role_name === item?.role_name
                                )?.[ele?.id]
                              }
                              onChange={(e) => {
                                setroleData(
                                  roleData.map((nested, i) =>
                                    nested.role_name === item?.role_name
                                      ? {
                                          ...nested,
                                          [ele?.id]: e.target.value,
                                        }
                                      : { ...nested }
                                  )
                                );
                              }}
                            >
                              {DropDown &&
                                [
                                  {
                                    value: 999,
                                    label: "Choose an Privilege",
                                  },
                                  ...DropDown,
                                ].map((item, i) => {
                                  return (
                                    <option value={item?.value}>
                                      {item?.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ alignSelf: "center" }}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={
                                roleData.find(
                                  (items) =>
                                    items?.role_name === item?.role_name
                                )?.[ele?.id] == "1"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setroleData(
                                  roleData.map((nested) =>
                                    nested.role_name === item?.role_name
                                      ? {
                                          ...nested,
                                          [ele?.id]: e.target.checked,
                                        } // Updates to true or false
                                      : nested
                                  )
                                );
                              }}
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <button className="Access-role-cancel-btn">Cancel</button>
      <button className="Access-role-save-changes-btn" onClick={handleUpdate}>
        Save Changes
      </button>
    </div>
    </>
  );
};

export default Role;
