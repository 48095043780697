import React, { useEffect, useMemo, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Select from "react-select";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "./DynamicTextInput.css";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";


import {
  useGetCitiesListByStateIdQuery,
  useGetCountryListQuery,
  useGetStateByCountryIdQuery,
} from "../../Redux/API/homeSlice";
import {
  useGetCustomerCandidatesQuery,
  useGetCustomerJobsQuery,
  useGetDepartmentDropDownQuery,
  useGetDropDownDataQuery,
  useGetSkillsQuery,
  usePostCustomCityMutation,
  usePostCustomCountryMutation,
  usePostCustomStateMutation,
} from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import CustomModal from '../Modal/Modal'
import { useSearchParams } from "react-router-dom";

// import {FilePreviewerThumbnail} from 'react-file-previewer';

// if (!Quill.imports['modules/imageResize']) {
// Quill.register('modules/imageResize', ImageResize);
// }

Quill.register("modules/imageResize", ImageResize);

/* Prabhu 07 Jan 2025 Dynamic Text Fields Function */
const DynamicTextInput = ({
  customStyle,
  system_Default,
  customInputs,
  dataForDropdown,
  onChangeCustomInput,
  remove,
  onChangeRemove,
  defaultValue,
  index,
  value,
  typeOftheField,
  labeleditable,
  inputEditable,
  onChangeValue,
  typeInput,
  placeholder,
  required,
  editable,
  parentInd
}) => {
  const fileRead = new FileReader();
  const [CountryListData, setCountryList] = useState();
  const [StateListData, setStateList] = useState();
  const [CitiesListData, setCitisList] = useState();
  const isAuth = useSelector((state) => state.auth);
  const [showButtons, setShowButtons] = useState(true);
  const [shouldPoll, setshouldPoll] = useState(false);
  const {
    data: CountryList,
    isSuccess: CountryListSuccess,
    refetch: CountryListRefetch,
  } = useGetCountryListQuery();

  function formatDateToYYYYMMDD(isoDateString) {
    const date = new Date(isoDateString); // Convert string to Date object
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure two-digit format
    
    return `${year}-${month}-${day}`;
    }

  const [title, settitle] = useState();
  const [isCustomModalOpen, setisCustomModalopen] = useState(false);
  const [params] = useSearchParams();
  const [style,setstyle] = useState("")
  const checkingParam = () => {
    const paramstype = params.get("type");
    if (paramstype?.includes("create")) {
      setShowButtons(false);
      setstyle("")
    } else if(paramstype?.includes("parse-resume")){
      setShowButtons(false);
      setstyle("")
    }else if(paramstype==="candidates"){
      setShowButtons(false)
    } else {
      setstyle('dynamic-text-input')
      // console.log("create is not there in toure")
    }
  };
  useEffect(() => {
    checkingParam();
  }, []);

  const ModalClose = () => {
    if (isCustomModalOpen) {
      setisCustomModalopen(!isCustomModalOpen);
    }
  };
  const [SkillsData,setSkillsData] = useState([])
  const {data:Skills,refetch:SkillsRefetch,isSuccess:SkillsSuccess} = useGetSkillsQuery({
    domain_name: isAuth?.user.domain_name
  })  

  useEffect(()=>{
    if(Skills?.status && Skills?.message){
        setSkillsData(Skills?.message || [])
    }
  },[SkillsSuccess])
  // const countryName = value[parentInd]?.fields.filter((item, i) => item?.label === "Country")[0]?.valueOftheField
  // const countryId = CountryList?.message?.find((item,i)=>item?.name===countryName)

  const countryId = useMemo(() => 
    CountryList?.message?.find(item => 
      item?.name === value[parentInd]?.fields.find(item => item?.label === "Country")?.valueOftheField
    )?.id, 
    [CountryList, value] 
  );

 
  const {
    data: StateList,
    isSuccess: StateListSuccess,
    refetch: StateListRefetch,
  } = useGetStateByCountryIdQuery({
    id: countryId || null,
  });
  const StateId = useMemo(() => 
    StateList?.message?.find(item => 
      item?.name === value[parentInd]?.fields.find(item => item?.label === "State")?.valueOftheField
    )?.id, 
    [StateList, value] 
  );



  
  const {
    data: CityList,
    isSuccess: CityListSuccess,
    refetch: CityListRefetch,
  } = useGetCitiesListByStateIdQuery({
    id: StateId || null,
  });

  useEffect(()=>{
    if(countryId){
      StateListRefetch()
    }
  },[countryId])

  useEffect(()=>{
    if (StateListSuccess && StateList?.status) {
      setStateList(StateList.message || []);
    }
  },[StateListSuccess,StateList])

  useEffect(()=>{
    if(StateId){
      CityListRefetch()
    }
  },[StateId])

  
  // useEffect(() => {
  //   StateListRefetch();
  //   if (StateListSuccess && StateList.status) {
  //     setStateList(StateList.message || []);
  //   }
  // }, [StateListSuccess,countryId,value,CountryList]);

  useEffect(() => {
    if (CityListSuccess && CityList.status) {
      setCitisList(CityList.message || []);
    }
  }, [CityListSuccess, CityList]);
  useEffect(() => {
    CountryListRefetch();
    if (CountryListSuccess && CountryList.status) {
      setCountryList(CountryList.message);
    }
  }, [CountryListSuccess, value]);
 
  const {
    data: DropDownData,
    isSuccess: DropdownSuccess,
    refetch: DropdownRefetch,
  } = useGetDropDownDataQuery(
    {
      domain_name: isAuth?.user?.domain_name,
      id: parseInt(dataForDropdown) || 1,
    },
    { refetchOnMountOrArgChange: true, pollingInterval: 1500 }
  );
  const [dropdownData, setDropDowndata] = useState();
  // console.log("Dropdown",dataForDropdown)

  useEffect(() => {
    const intervalId = setInterval(() => {
      DropdownRefetch();
      setDropDowndata(DropDownData?.message || []);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [isCustomModalOpen, DropDownData?.status, dataForDropdown]);
  const [Details, setDetails] = useState({
    title: "Create",
    id: null,
  });

  const mountDropDownData = async () => {
    DropdownRefetch();
    if (DropdownSuccess && DropDownData?.status) {
      setDropDowndata(DropDownData?.message || []);
      setTimeout(() => {
        setDropDowndata(DropDownData?.message || []);
        setshouldPoll(false);
      }, 2000);
    }
    if (dataForDropdown !== 1) {
      setDetails({
        title: "Update",
        id: dataForDropdown,
      });
    }
  };
  useEffect(() => {
    mountDropDownData();
  }, [
    isCustomModalOpen,
    DropDownData?.status,
    dataForDropdown,
    customInputs,
    DropdownRefetch,
  ]);
  // const ConvertFileToBase64 = (file) => {
  //   // return new Promise((resolve, reject) => {
  //   //   try {
  //   //     const result = fileRead.readAsDataURL(file);
  //   //     fileRead.onload = () => resolve(fileRead.result);
  //   //   } catch (err) {
  //   //     reject(err);
  //   //   }
  //   // });
  //   try{
  //     const result = fileRead.readAsDataURL(file)
  //     return fileRead?fileRead?.result:""
  //   }catch(err){
  //     return ""
  //   }
  // };
  const ConvertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result); 
      reader.onerror = (error) => reject(error); 
      
      reader.readAsDataURL(file); 
    });
  };
  
 
  
  

  const editor = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  const [selectedNamefromDropDown, setSelectedName] = useState({
    country: "",
    state: "",
    city: "",
  });
  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const handleSelectedNameChange = (selectedName, label) => {
    label === "Country" &&
      setSelectedName({
        ...selectedNamefromDropDown,
        country: selectedName.dataset.name,
      });
    label === "State" &&
      setSelectedName({
        ...selectedNamefromDropDown,
        state: selectedName.dataset.name,
      });
    label === "City" &&
      setSelectedName({
        ...selectedNamefromDropDown,
        city: selectedName.dataset.name,
      });
  };


  const [ModalplaceHolder, setmodalplaceholder] = useState();
  // useEffect(()=>{
  //     if(selectedNamefromDropDown.country==='Others'){
  //         setisCustomModalopen(!isCustomModalOpen)
  //         settitle("Add Custom Country Name")
  //         setmodalplaceholder('Enter the Country name you want to add')
  //     }
  // },[selectedNamefromDropDown.country])

  // useEffect(()=>{
  //     if(selectedNamefromDropDown.state==='Others'){
  //         setisCustomModalopen(!isCustomModalOpen)
  //         settitle("Add Custom State Name")
  //         setmodalplaceholder('Enter the State name you want to add')
  //     }
  // },[selectedNamefromDropDown.state])

  useEffect(() => {
    if (selectedNamefromDropDown.city === "Others") {
     
      settitle("Add Custom City Name");
      setmodalplaceholder("Enter the City name you want to add");
    }
  }, [selectedNamefromDropDown.city]);
  const [AllJob,setAllJobs] = useState([])
  const [AllCandidates,setAllCandiates] = useState([])
  const {
    data:AllJobs, refetch:Alljobsrefetch, isSuccess:AllJobsSuccess
  } = useGetCustomerJobsQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id:isAuth?.user?.role_id,
    user_id:isAuth?.user?.user_id
  })


  const {
   data:allCandidates, refetch:allcandidaterefetch, isSuccess:allCandidateSucess
  } = useGetCustomerCandidatesQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id:isAuth?.user?.role_id,
    user_id:isAuth?.user?.user_id
  })
  useEffect(()=>{
    if(AllJobs?.status && AllJobs?.message?.length>0){
      setAllJobs(AllJobs?.message?.map((item,i)=>({
          id:item?.job_id,
          label:item?.job_title
      })))
    }
  },[AllJobsSuccess])
  useEffect(()=>{

    if(allCandidates?.status && allCandidates?.message?.length>0){
      setAllCandiates(allCandidates?.message?.map((item,i)=>({
        id:item?.candidate_id,
        label:`${item?.first_name} ${item?.last_name?item?.last_name:""}`
    })))
    }
  },[allCandidateSucess])

  useEffect(()=>{
    if(currentData?.label==='Interview For'){

    }
  },[customInputs])
  const RemoveInput = (index) => {
    
    try {
      if (!customInputs[parentInd]?.fields) return;
        
    
        let updatedCustomInputs = [...customInputs];
        updatedCustomInputs[parentInd] = {
            ...updatedCustomInputs[parentInd],
            fields: updatedCustomInputs[parentInd].fields.filter((_, i) => i !== index)
        };

        let updatedValue = [...value];
        updatedValue[parentInd] = {
            ...updatedValue[parentInd],
            fields: updatedValue[parentInd].fields.filter((_, i) => i !== index)
        };
        onChangeCustomInput(updatedCustomInputs);
        onChangeValue(updatedValue);

      onChangeRemove(!remove);
    } catch (err) {
      console.log(err);
    }
  };
  
  const cityId = value[parentInd]?.fields.filter((item, i) => item.label === "City");
 
  const [itemsDisplay, setitemDisplay] = useState();
  const [itemId, setItemId] = useState();
  const [input, setinput] = useState("");

  const [departmentDropDown,setDepartmentDropDown] = useState()
const {
    data:deptDropDown,
    refetch:departmentRefetch,
    isSuccess:DepartmentSucccess
} = useGetDepartmentDropDownQuery({
    domain_name:isAuth?.user?.domain_name
})

useEffect(()=>{
    if(deptDropDown?.status){
     setDepartmentDropDown(deptDropDown?.message)
    }
},[DepartmentSucccess])

  const currentData = value[parentInd]?.fields[index];
  const CurrentProps = customInputs[index];
  const [postCountry] = usePostCustomCountryMutation();
  const [postState] = usePostCustomStateMutation();
  const [postCity] = usePostCustomCityMutation();

  const handleSubmit = async () => {
    try {
      if (selectedNamefromDropDown?.city === "Others" && input) {
        if (itemsDisplay?.state !== "Choose the State") {
        
          const response = await postCity({
            domain_name: isAuth?.user?.domain_name,
            cityName: input,
           // stateId: itemId?.state,
           stateId:StateId
          });
          if(response.data?.status){
            CityListRefetch()
          }
          console.log("city", response);
        } else {
          console.log("Choose the State");
        }
      } else {
        console.log("Choose the City");
      }
    } catch (err) {
      console.log(err);
    }
  };

  
  return (
    <div className={'dynamic-text-input'} style={showButtons && typeOftheField==='editorInput'?{display:'flex',flexDirection:'row',width:'fit-content',height:'50vh',backgroundColor:'whitesmoke',alignItems:'center',alignContent:'flex-end'}:showButtons?{display:'flex',flexDirection:'row',width:'fit-content',backgroundColor:'red',height:'15vh',backgroundColor:'whitesmoke',alignItems:'center',alignContent:'flex-end'}:{ width: '100%',maxWidth: '600px'}}>
      <div>
  {typeOftheField === "checkBoxInput" ? (
    <div style={showButtons?{display:'flex',width:'90vw',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}> 
    <div style={showButtons?{display:'flex',flexDirection:'row',width:'50vw',columnGap:30,marginLeft:'10px',alignItems:'center'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
        
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        readOnly={labeleditable}
        className="label-input"
        style={showButtons?{width:'22.5vw',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <input
        checked={
          currentData?.valueOftheField === "1" ||
          currentData?.valueOftheField === "true"
            ? true
            : currentData?.valueOftheField === "0" ||
              currentData?.valueOftheField === "false"
            ? false
            : currentData?.valueOftheField
            ? true
            : false
        }

        onChange={(e) => {
        //  console.log(e.target.checked)
        if(currentData?.label==='Currently Working' || currentData?.label==='Currently Pursuing'){
       //   console.log("yest",item?.fields[index-1]?.label)
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  valueOftheField: e.target.checked
                }:index-1===i && item?.fields[index-1]?.label==='End Date'? {
                  ...ele,
                  valueOftheField:"",
                  settings:{
                    disable:e.target.checked
                  }
                }:
                {...ele}
              )
            }:{...item}
          )
          
          onChangeValue(needtoUpdate);
        }else{
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  valueOftheField: e.target.checked
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }
        }}
        type={typeInput}
      /></div>
      <div style={showButtons?{display:'flex',height:'fit-content',columnGap:10,alignSelf:'center',width:'fit-content'}:{display:'flex',height:'fit-content',columnGap:10,alignSelf:'end',width:'fit-content'}}>
      {showButtons && (
        <>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center',backgroundColor:'transparent'}:{}}
              disabled={system_Default}

              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit />
            </button>
          ) : (
            <button
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: true
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              Save Changes
            </button>
          )}
          <button
          style={showButtons?{position:'absolute',left:'87%',alignSelf:'center'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
        </>
      )}
      </div>
    </div>
  ) : typeOftheField === "radioInput" ? (
    <div style={showButtons?{display:'flex',width:'90vw',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}> {/*Radio input Main Container*/}
    <div style={showButtons?{display:'flex',flexDirection:'row',width:'50vw',columnGap:30,marginLeft:'10px',alignItems:'center'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        readOnly={labeleditable}
        className="dynamic-label-input"
        style={showButtons?{width:'22.5vw',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <div style={showButtons?{display:'flex',width:'fit-content',alignItems:'center',justifyContent:'center',flexDirection:'row',columnGap:15}:{display:'flex',width:'fit-content',alignItems:'center',justifyContent:'center',height:'6vh',flexDirection:'row'}}>
      {dropdownData &&
        dropdownData?.map((item, i) => {
          return (
            <fieldset style={{display:'flex', gap:'1%',alignItems:'center',width:'fit-content',justifyContent:'center',translate:'0 10px 0',borderRadius:'40px'}}>
              <input
                type={typeInput}
                value={item.label}
                checked={
                  value[parentInd]?.fields.find((item, idx) => idx === index)?.valueOftheField ===
                  item.label
                }
                onChange={(e) => {
                  const needtoUpdate = value.map((item,idx)=>
                    parentInd===idx?{
                      ...item,
                      fields:item?.fields.map((ele,i)=>
                        index===i?{
                          ...ele,
                          valueOftheField: e.target.value
                        }:{...ele}
                      )
                    }:{...item}
                  )
                  onChangeValue(needtoUpdate);
                }}
              
              />
              <label  htmlFor={item}>{item.label}</label>
            </fieldset>
          );
        })}
      </div>
      </div> 
      <div style={showButtons?{display:'flex',height:'fit-content',columnGap:10,alignSelf:'center',width:'fit-content'}:{display:'flex',height:'fit-content',columnGap:10,alignSelf:'end',width:'fit-content'}}>
      {showButtons && (
        <div style={{display:'flex',flexDirection:'row', gap:'5%',backgroundColor:'transparent'}}>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center'}:{}}
              disabled={system_Default}
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit enableBackground={false}/>
              
            </button>
          ) : (
            <button
            onClick={()=>{
              const needtoUpdate = customInputs.map((item,idx)=>
                parentInd===idx?{
                  ...item,
                  fields:item?.fields.map((ele,i)=>
                    index===i?{
                      ...ele,
                      labeleditable: true
                    }:{...ele}
                  )
                }:{...item}
              )
              onChangeCustomInput(needtoUpdate);
            }}
            >
              Save Changes
            </button>
          )}
          <button
          style={showButtons?{position:'absolute',left:'87%',alignSelf:'center'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
        </div>
      )}
      </div>
    </div>
  ) : typeOftheField === "dropdownInput" ? (
    <div style={showButtons?{display:'flex',width:'90vw',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}> {/*dropdown input Main Container*/}
    <div style={showButtons?{display:'flex',flexDirection:'row',width:'50vw',columnGap:30,marginLeft:'10px'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
          
        }}
        readOnly={labeleditable}
        className="dynamic-label-input"
        style={showButtons?{width:'25vw',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <div >
      <select
      style={showButtons?{width:'25vw'}:{width:'100%'}}
      // style={{width:'95%'}}
      className={showButtons ? "dropdown-select-wide" : "dropdown-select"}
      // style={showButtons ? { width:"30vw",backgroundColor:'red'} : { backgroundColor: "green" }}
        value={currentData?.valueOftheField || ""}
        // className="dropdown-select"
        onChange={(e) => {
          const selectedName = e.target.options[e.target.selectedIndex];
         
          handleSelectedNameChange(selectedName, currentData?.label);
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  valueOftheField: e.target.value
                }:ele?.label==='Job ID' && currentData?.label==='Interview For'?{
                  ...ele,
                    valueOftheField: AllJob?.find((items,i)=>items?.label===e.target.value)?.id
                }:ele?.label==='Candidate ID' && currentData?.label==='Candidate Name'?{
                ...ele,
                valueOftheField: AllCandidates?.find((items,i)=>items?.label===e.target.value)?.id}
                :{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
          // if(currentData?.label==='Interview For'){
          //   console.log("Yes")
          //   const needtoUpdate = value.map((item,idx)=>
          //     parentInd===idx?{
          //       ...item,
          //       fields:item?.fields.map((ele,i)=>
          //         ele?.label==="Job ID"?{
          //           ...ele,
          //           valueOftheField: AllJob?.find((items,i)=>items?.label===e.target.value)?.id,
          //         }:{...ele}
          //       )
          //     }:{...item}
          //   )
          //   onChangeValue(needtoUpdate)
          // }
        }}
      >
        {currentData?.label === "Country" ? (
          <>
            {CountryListData &&
              [{ name: "Choose the Country" }, ...CountryListData].map(
                (item, i) => {
                  return (
                    <option style={showButtons?{width:'20vw',height:'5vh'}:{width:'100%'}} value={item?.name} data-name={item?.name}>
                      {item?.name}
                    </option>
                  );
                }
              )}
          </>
        ) : currentData?.label === "State" ? (
          <>
            {StateListData &&
              [{ name: "Choose the State" }, ...StateListData].map(
                (item, i) => {
                  return (
                    <option style={showButtons?{width:'20vw',height:'5vh'}:{width:'100%'}} value={item?.name} data-name={item?.name}>
                      {item?.name}
                    </option>
                  );
                }
              )}
          </>
        ) : currentData?.label === "City" ? (
          CitiesListData &&
          [
            { name: "Choose the City" },
            ...CitiesListData,
            { name: "Others" },
          ].map((item, i) => {
            return (
              <option style={showButtons?{width:'20vw',height:'5vh'}:{width:'100%'}} value={item?.name} data-name={item?.name}>
                {item?.name}
              </option>
            );
          })
        ) : currentData?.label === 'Department Name'?(
         departmentDropDown &&
         (departmentDropDown.length>0? [{id:0,name:"Choose an Department"},...departmentDropDown]:[{id:0,name:"No Department Found"}]).map((item,i)=>{
             return (
                <option style={showButtons?{width:'20vw',height:'5vh'}:{width:'100%'}} value={item?.name} data-name={item?.name}>
                {item?.name}
                </option>
             )
         })
        ) : currentData?.label === 'Interview For'?(
         AllJob && AllJob?.map((item, i) => {
            return (
              <option value={item?.label} data-name={item?.label}>
                {item.label}
              </option>
            );
          })
        ): currentData?.label ==='Candidate Name'?(
        AllCandidates && AllCandidates?.map((item, i) => {
            return (
              <option value={item?.label} data-name={item?.label}>
                {item.label}
              </option>
            );
          })
        ):( dropdownData &&
          dropdownData?.map((item, i) => {
            return (
              <option style={showButtons?{width:'20vw',height:'5vh'}:{width:'100%'}} value={item?.label} data-name={item?.label}>
                {item.label}
              </option>
            );
          })
        )}
      </select>
      </div>
      {selectedNamefromDropDown.city === "Others" && (
        <>
          <input
            type="text"
            value={input}
            onChange={(e) => setinput(e.target.value)}
            placeholder={ModalplaceHolder}
            className="other-input"
          />
          <button onClick={handleSubmit}>Save Changes</button>
        </>
      )}
     </div>
     <div style={showButtons?{display:'flex',height:'fit-content',columnGap:10,alignSelf:'center',width:'fit-content'}:{display:'flex',height:'fit-content',columnGap:10,alignSelf:'end',width:'fit-content'}}>
      {showButtons && (
        <>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center',backgroundColor:'transparent'}:{}}
              disabled={system_Default}
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit enableBackground={false} />
            </button>
          ) : (
            <button
            onClick={()=>{
              const needtoUpdate = customInputs.map((item,idx)=>
                parentInd===idx?{
                  ...item,
                  fields:item?.fields.map((ele,i)=>
                    index===i?{
                      ...ele,
                      labeleditable: true
                    }:{...ele}
                  )
                }:{...item}
              )
              onChangeCustomInput(needtoUpdate);
            }}
            >
              Save Changes
            </button>
          )}
          <button
            style={showButtons?{position:'absolute',left:'87%'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
          <button
          style={showButtons?{border:"1px solid #A4A4A4",marginLeft:'20%'}:{}}
            onClick={() => {
              setisCustomModalopen(!isCustomModalOpen);
              settitle("Create Dropdown Data");
            }}
          >
            <IoSettingsOutline />
          </button>
        </>
      )}
      </div>
    </div>
  ) : typeOftheField === "multiselectInput" ? (
    <div style={showButtons?{display:'flex',width:'90vw',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}>
      <div style={showButtons?{display:'flex',flexDirection:'row',width:'50vw',columnGap:30,marginLeft:'10px'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        readOnly={labeleditable}
        className="dynamic-label-input"
        style={showButtons?{width:'23vw',alignSelf:'center',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <div className="multiselect-container" style={{width:'20.5vw',border:'1px solid #007bff',borderRadius:'4px'}} >
        <Select
    //    styles={}
          value={
            typeof currentData?.valueOftheField === "string"
              ? JSON.parse(currentData?.valueOftheField)
              : currentData?.valueOftheField
          }
          onChange={(selectedOption) => {
            const needtoUpdate = value.map((item,idx)=>
              parentInd===idx?{
                ...item,
                fields:item?.fields.map((ele,i)=>
                  index===i?{
                    ...ele,
                    valueOftheField: JSON.stringify(selectedOption)
                  }:{...ele}
                )
              }:{...item}
            )
            onChangeValue(needtoUpdate);
          }}
          isSearchable={true}
          options={currentData?.label==='Skills'?SkillsData:dropdownData || []}
          isMulti
          // className={showButtons ? "dropdown-select-wide" : "dropdown-select"}
        />
      </div>
      </div>
      <div style={showButtons?{display:'flex',height:'fit-content',columnGap:10,alignSelf:'center',width:'fit-content'}:{display:'flex',height:'fit-content',columnGap:10,alignSelf:'end',width:'fit-content'}}>
      {showButtons && (
        <>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center',backgroundColor:'transparent'}:{}}
              disabled={system_Default}
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit enableBackground={false} />
            </button>
          ) : (
            <button
            onClick={()=>{
              const needtoUpdate = customInputs.map((item,idx)=>
                parentInd===idx?{
                  ...item,
                  fields:item?.fields.map((ele,i)=>
                    index===i?{
                      ...ele,
                      labeleditable: true
                    }:{...ele}
                  )
                }:{...item}
              )
              onChangeCustomInput(needtoUpdate);
            }}
            >
              Save Changes
            </button>
          )}
          <button
          style={showButtons?{position:'absolute',left:'87%',alignSelf:'center'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
          <button
          style={showButtons?{border:"1px solid #A4A4A4",marginLeft:'20%'}:{}}
            onClick={() => {
              setisCustomModalopen(!isCustomModalOpen);
              settitle("Create MultiSelect Data");
            }}
          >
            <IoSettingsOutline />

          </button>
        </>
      )}
      </div>
    </div>
  ) : typeOftheField === "editorInput" ? (
    <div style={params.get("type")==="candidates"?{display:'flex',flexDirection:'row',width:'40%',maxWidth:'40%',minWidth:'40%',padding:'5px',justifyContent:'center',columnGap:10}:showButtons?{display:'flex',width:'90vw',height:'fit-content',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}>
    <div style={params.get("type")==="candidates"?{display:'flex',flexDirection:'column',width:'100%'}:showButtons?{display:'flex',height:'fit-content',flexDirection:'row',width:'75vw',columnGap:30,marginLeft:'10px',alignItems:'center'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        readOnly={labeleditable}
        className="label-input"
        style={showButtons?{width:'25vw',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <ReactQuill
      // style={{backgroundColor:'red'}}
        theme="snow"
        value={currentData?.valueOftheField}
        placeholder={placeholder}
        modules={editor}
        formats={formats}
        onChange={(html) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  valueOftheField: html
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        style={params.get("type")==="candidates"?{width:"45vw"}:showButtons?{}:{width:"89vw"}}
        className="editor-input"
      />
    </div>
    <div style={showButtons?{display:'flex',height:'fit-content',columnGap:10,alignSelf:'center',width:'fit-content'}:{display:'flex',height:'fit-content',columnGap:10,alignSelf:'end',width:'fit-content'}}>
      {showButtons && (
        <>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center'}:{}}
              disabled={system_Default}
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit />
            </button>
          ) : (
            <button
            onClick={()=>{
              const needtoUpdate = customInputs.map((item,idx)=>
                parentInd===idx?{
                  ...item,
                  fields:item?.fields.map((ele,i)=>
                    index===i?{
                      ...ele,
                      labeleditable: true
                    }:{...ele}
                  )
                }:{...item}
              )
              onChangeCustomInput(needtoUpdate);
            }}
            >
              Save Changes
            </button>
          )}
          <button
            style={showButtons?{position:'absolute',left:'87%',alignSelf:'center'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
        </>
      )}
    </div>
    </div>
  ) : typeOftheField === "decimalInput" ? (
    <div style={showButtons?{display:'flex',width:'90vw',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}> {/*dropdown input Main Container*/}
    <div style={showButtons?{display:'flex',flexDirection:'row',width:'50vw',columnGap:30,marginLeft:'10px'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        readOnly={labeleditable}
        className="dynamic-label-input"
        style={showButtons?{width:'25vw',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <input
      style={showButtons?{width:'25vw'}:{width:'100%'}}
        placeholder={placeholder}
        className="decimal-input"
        value={currentData.valueOftheField}
        step={currentData?.setting?.step}
        min={currentData?.setting?.minValue}
        max={currentData?.setting?.maxValue}
        readOnly={inputEditable}
        type={typeInput}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  valueOftheField: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
      />
      {showButtons && (
        <>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center',backgroundColor:'transparent'}:{}}
              disabled={system_Default}
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit enableBackground={false}/>
            </button>
          ) : (
            <button
              onClick={()=>{
                if (!currentData.label || !currentData.label.trim()) {
                  alert("Error: Placeholder cannot be empty.");
                  return;
                }
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: true
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              Save Changes
            </button>
          )}
          <button
          style={showButtons?{position:'absolute',left:'87%'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
        </>
      )}
      </div>
    </div>
  ) : (
    <div style={showButtons?{display:'flex',width:'90vw',alignItems:'center',marginLeft:10}:{display:'flex',flexDirection:'row',width:'90%',maxWidth:'90%',minWidth:'90%',padding:'5px',justifyContent:'center',columnGap:10}}> {/*dropdown input Main Container*/}
    <div style={showButtons?{display:'flex',flexDirection:'row',width:'50vw',columnGap:30,marginLeft:'10px'}:{display:'flex',flexDirection:'column',width:'100%'}}>
      <input
        placeholder={currentData?.label?currentData.label : ""}
        type={"text"}
        onChange={(e) => {
          const needtoUpdate = value.map((item,idx)=>
            parentInd===idx?{
              ...item,
              fields:item?.fields.map((ele,i)=>
                index===i?{
                  ...ele,
                  label: e.target.value
                }:{...ele}
              )
            }:{...item}
          )
          onChangeValue(needtoUpdate);
        }}
        readOnly={labeleditable}
        className="dynamic-label-input"
        style={showButtons?{width:'24vw',height:'6vh',borderRadius:'5px'}:{border:'none', outline:'none',width:'100%',padding:'8px 0'}}
      />
      <input
      // style={{width:'100%'}}
      style={showButtons?{width:'25vw'}:{width:'95%'}} //default inputs styles handling width of the feilds 
        placeholder={placeholder}
        className="default-input"
        value={
          typeInput === "file"
            ? ""
            : typeInput === "datetime-local"
            ? formatDateForInput(currentData?.valueOftheField)
            : typeInput==='date'?
              formatDateToYYYYMMDD(currentData?.valueOftheField)
            : currentData?.valueOftheField
        }
        readOnly={inputEditable}
        disabled={typeInput==='date'?currentData?.settings?.disable:false}
        type={typeInput}

        onChange={async(e) => {
          if (typeInput === "file") {
            const needtoUpdate = await Promise.all(
              value.map(async (item, idx) =>
                parentInd === idx
                  ? {
                      ...item,
                      fields: await Promise.all(
                        item.fields.map(async (ele, i) =>
                          index === i
                            ? {
                                ...ele,
                                valueOftheField: await ConvertFileToBase64(e.target.files[0]), // Ensure conversion completes
                              }
                            : { ...ele }
                        )
                      ),
                    }
                  : { ...item }
              )
            );
            //  console.log(needtoUpdate[needtoUpdate.length-1]?.fields[0])

              onChangeValue(needtoUpdate);
          } else {
            const needtoUpdate = value.map((item,idx)=>
              parentInd===idx?{
                ...item,
                fields:item?.fields.map((ele,i)=>
                  index===i?{
                    ...ele,
                    valueOftheField: e.target.value
                  }:{...ele}
                )
              }:{...item}
            )
            onChangeValue(needtoUpdate);
          }
        }}
      />
      </div>
      <div style={showButtons?{display:'flex',height:'fit-content',columnGap:10,alignSelf:'center',width:'fit-content'}:{display:'flex',height:'fit-content',columnGap:10,alignSelf:'end',width:'fit-content'}}>
      {showButtons && (
        <>
          {labeleditable ? (
            <button
            style={showButtons?{position:'absolute',left:'25%',alignSelf:'center',backgroundColor:'transparent'}:{}}
              disabled={system_Default}
              onClick={()=>{
                const needtoUpdate = customInputs.map((item,idx)=>
                  parentInd===idx?{
                    ...item,
                    fields:item?.fields.map((ele,i)=>
                      index===i?{
                        ...ele,
                        labeleditable: false
                      }:{...ele}
                    )
                  }:{...item}
                )
                onChangeCustomInput(needtoUpdate);
              }}
            >
              <MdModeEdit enableBackground={false}  />
            </button>
          ) : (
            <button
            onClick={()=>{
              const needtoUpdate = customInputs.map((item,idx)=>
                parentInd===idx?{
                  ...item,
                  fields:item?.fields.map((ele,i)=>
                    index===i?{
                      ...ele,
                      labeleditable: true
                    }:{...ele}
                  )
                }:{...item}
              )
              onChangeCustomInput(needtoUpdate);
            }}
            >
              Save Changes
            </button>
          )}
          <button
          style={showButtons?{position:'absolute',left:'87%',alignSelf:'center'}:{}}
            disabled={system_Default}
            onClick={() => {
              RemoveInput(index);
            }}
            className="remove"
          >
            <MdDelete />
          </button>
        </>
      )}
      {typeInput === "file" && (
        <p>{currentData.valueOftheField?.toString()}</p>
      )}
        </div>
    </div>
  )}
  </div>
  {isCustomModalOpen && (
    <CustomModal
      isOpen={isCustomModalOpen}
      details={Details}
      customInputs={customInputs}
      onChangeValue={onChangeValue}
      index={index}
      parent_ind={parentInd}
      type={typeOftheField}
      placeholder={ModalplaceHolder}
      close={setisCustomModalopen}
      title={title}
    />
  )}
  <div style={{alignSelf:'center',position:'absolute',right:'50px'}}>
  {showButtons && (
    <button
    // style={{marginTop:'40%'}}
      onClick={() => {
        setisCustomModalopen(!isCustomModalOpen);
      }}
    >
      open
    </button>
  )}
  </div>
</div>
  );
};

export default DynamicTextInput;
