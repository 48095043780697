import React, { useEffect, useMemo, useState } from "react";
import "./Personalsetting.css";
import user2 from "./user2.png";
import { useGetUserProfileQuery } from "../../../../Redux/API/atsSlice";
import { useUpdateProfileMutation } from "../../../../Redux/API/atsSlice";
import { FaEdit } from "react-icons/fa";
import { Edit, Save } from "lucide-react";
import { FaCaretDown } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  useGetCountryListQuery,
  useGetStateByCountryIdQuery,
  useGetCitiesListByStateIdQuery,
} from "../../../../Redux/API/homeSlice";

import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../Redux/API/apiSlice";

const PersonalSettingsForm = () => {
  const [profile, setprofile] = useState({
    first_name: "",
    last_name: "",
    phonecode: "",
    mobile_number: "",
    office_ph_code: "",
    office_number: "",
    emailid: "",
    profile_pic: "",
    about_yourself: "",
    inbox_mail: "",
    country: "",
    state: "",
    city: "",
  });
  const [activeTab, setActiveTab] = useState("personal-details");
  const [CountryListData, setCountryList] = useState();
  const [StateListData, setStateList] = useState();
  const [CitiesListData, setCitisList] = useState();
  const [isEditingContact, setIsEditingContact] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isEditingProfilePic, setIsEditingProfilePic] = useState(false);
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [selectedNamefromDropDown, setSelectedName] = useState({
    country: "",
    state: "",
    city: "",
  });

  const isAuth = useSelector((state) => state.auth);
  const user_id = isAuth?.user?.user_id;
  //   console.log("authhh=>",user_id);

  const { data } = useGetUserProfileQuery({ user_id });
  const userProfile = data?.message || {};
  // console.log("USER", userProfile);
  useEffect(() => {
    setprofile({
      first_name: userProfile?.first_name,
      last_name: userProfile?.last_name,
      emailid: userProfile?.emailid,
      profile_pic: userProfile?.profile_pic,
      phonecode: userProfile?.phonecode,
      mobile_number: userProfile?.mobile_number,
      about_yourself: userProfile?.about_yourself,
      country: userProfile?.country,
      city: userProfile?.city,
      state: userProfile?.state,
      office_number: userProfile?.office_number,
      office_ph_code: userProfile?.office_ph_code,
      inbox_mail: userProfile?.inbox_mail,
    });
  }, [userProfile]);
  const {
    data: CountryList,
    isSuccess: CountrySuccess,
    refetch: CountryRefetch,
  } = useGetCountryListQuery();

  const countryId = useMemo(
    () =>
      CountryList?.message?.find((item) => item?.name === profile?.country)?.id,
    [CountryList, profile]
  );

  const {
    data: StateList,
    isSuccess: StateListSuccess,
    refetch: StateListRefetch,
  } = useGetStateByCountryIdQuery({
    id: countryId || null,
  });
  // console.log(countryId);
  //   useEffect(()=>{

  //   },)
  const StateId = useMemo(
    () => StateList?.message?.find((item) => item?.name === profile?.state)?.id,
    [StateList, profile]
  );

  const {
    data: CityList,
    isSuccess: CityListSuccess,
    refetch: CityListRefetch,
  } = useGetCitiesListByStateIdQuery({
    id: StateId || null,
  });
  // console.log("CITY", CityList, "", StateId);
  useEffect(() => {
    if (countryId) {
      StateListRefetch();
    }
  }, [countryId, profile]);

  useEffect(() => {
    if (CountrySuccess && StateList?.status) {
      setStateList(StateList.message || []);
    }
  }, [StateListSuccess, StateList]);
  useEffect(() => {
    if (StateId) {
      CityListRefetch();
    }
  }, [StateId]);

  useEffect(() => {
    if (CityListSuccess && CityList.status) {
      setCitisList(CityList.message || []);
    }
  }, [CityListSuccess, CityList]);
  useEffect(() => {
    CountryRefetch();
    if (CountrySuccess && CountryList.status) {
      setCountryList(CountryList.message);
    }
  }, [CountrySuccess]);
  const ConvertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

   const togglePassword = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const [updateUserProfile] = useUpdateProfileMutation();

  const handleSaveProfile = async () => {
    try {
      const response = await updateUserProfile({
        user_id: user_id,
        data: profile,
        domain_name: isAuth?.user?.domain_name,
      }).unwrap();


      setprofile(response.updatedProfile || profile);

      setIsEditingContact(false);
      setIsEditingLocation(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="personal-settings-container">
      {/* Tabs */}
      <div className="settings-tabs">
        <button
          className={activeTab === "personal-details" ? "active" : ""}
          onClick={() => setActiveTab("personal-details")}
        >
          Personal Details
        </button>
        {/* <button
          className={activeTab === "change-password" ? "active" : ""}
          onClick={() => setActiveTab("change-password")}
        >
          Change Password
        </button>
        <button
          className={activeTab === "other-preferences" ? "active" : ""}
          onClick={() => setActiveTab("other-preferences")}
        >
          Other Preferences
        </button> */}
      </div>

      {/* Personal Details Section */}
      {activeTab === "personal-details" && (
        <div className="personal-details-section">
          {/* Contact Details Section */}
          <div className="contact-details">
            {/* Left - Form Fields */}
            <div className="contact-form">
              <div style={{ display: "flex", marginBottom: "1%" }}>
                <div className="contact-detail-header">
                  <h4>Contact Details</h4>
                </div>
                {!isEditingContact && (
                  
                  <button
                    className="personal-setting-edit"
                    onClick={() => setIsEditingContact(true)}
                  >
                    <FaEdit className="personal-setting-edit" />
                  </button>
                )}
              </div>
              <div className="personal-form-group">
                <div className="personal-input-group">
                  <label>First Name</label>
                  <input
                    className="personal-input-feild"
                    style={{ fontFamily: "poppins" }}
                    type="text"
                    value={profile.first_name || ""}
                    readOnly={!isEditingContact}
                    onChange={(e) =>
                      setprofile({ ...profile, first_name: e.target.value })
                    }
                  />
                </div>
                <div className="personal-input-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={profile.last_name || ""}
                    readOnly={!isEditingContact}
                    onChange={(e) =>
                      setprofile({ ...profile, last_name: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="personal-form-group">
              <div className="personal-input-group">
  <label>Mobile</label>
  <div className="personal-mobile-input">
    <select
      value={profile.phonecode}
      onChange={(e) =>
        setprofile({ ...profile, phonecode: e.target.value })
      }
    >
      {CountryList?.status &&
        CountryList?.message.map((item, i) => (
          <option key={i} value={item?.phonecode}>
            {item?.phonecode}
          </option>
        ))}
    </select>
    <input
      type="text"
      value={profile.mobile_number || ""}
      readOnly={!isEditingContact}
      onChange={(e) => {
        setprofile({
          ...profile,
          mobile_number: e.target.value,
        });
      }}
    />
  </div>
</div>
                <div className="personal-input-group">
                  <label>Office Number</label>
                  <div className="personal-mobile-input">
                    <select
                    // style={{background:'red'}}
                      value={profile.office_ph_code}
                      onChange={(e) =>
                        setprofile({
                          ...profile,
                          office_ph_code: e.target.value,
                        })
                      }
                    >
                      {CountryList?.status &&
                        CountryList?.message.map((item, i) => (
                          <option key={i} value={item?.phonecode}>
                            {item?.phonecode}
                          </option>
                        ))}
                    </select>
                    <input
                      type="text"
                      value={profile.office_number || ""}
                      readOnly={!isEditingContact}
                      onChange={(e) => {
                        setprofile({
                          ...profile,
                          office_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="personal-form-group">
                <div className="personal-input-group">
                  <label>Email ID</label>
                  <input
                    type="email"
                    value={profile.emailid || ""}
                    readOnly={!isEditingContact}
                    onChange={(e) =>
                      setprofile({ ...profile, emailid: e.target.value })
                    }
                  />
                </div>
                <div className="personal-input-group">
                  <label>Resume Inbox</label>
                  <input
                    type="email"
                    readOnly={!isEditingContact}
                    value={profile.inbox_mail}
                    onChange={(e) =>
                      setprofile({ ...profile, inbox_mail: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="personal-input-group">
                <label>About Yourself</label>
                <textarea
                  value={profile.about_yourself || ""}
                  readOnly={!isEditingContact}
                  onChange={(e) =>
                    setprofile({ ...profile, about_yourself: e.target.value })
                  }
                />
              </div>
              {isEditingContact && (
                <div className="company-form-actions">
                <button className="company-cancel-btn" onClick={() => setIsEditingContact(false)}>
               Cancel
                </button>
                <button className="company-save-btn" onClick={handleSaveProfile}>
                 Save Changes
                </button>
              </div>

              )}
            </div>

            {/* Right - Profile Picture */}
            <div className="profile-section">
  <div className="image-wrapper">
    <img
      src={
        profile.profile_pic?.startsWith("public")
          ? `${BASE_URL}${profile?.profile_pic}`
          : profile?.profile_pic || user2
      }
      alt="Profile"
      className="profile-image"
    />
    {isEditingContact && (
      <>
        <label htmlFor="profile-upload" className="upload-icon">
        <Edit/>
        </label>
        <input
        style={{display:'none'}}
          type="file"
          id="profile-upload"
          accept="image/*"
          onChange={async (e) => {
            const file = e.target.files[0];
            if (file) {
              const base64Image = await ConvertFileToBase64(file);
              setprofile((prev) => ({
                ...prev,
                profile_pic: base64Image,
              }));
            }
          }}
        />
      </>
    )}
  </div>
  <h4 className="profile-name">{userProfile.first_name}</h4>
  <p className="profile-role">{userProfile.role}</p>
</div>
         
          </div>

          {/* Location Details Section */}
          <div className="location-details">
            <div style={{ display: "flex" }}>
              <h4>Location Details</h4>
              {!isEditingLocation && (
                
                <button
                  className="personal-setting-edit"
                  onClick={() => setIsEditingLocation(true)}
                >
                  <FaEdit className="personal-setting-edit" />
                </button>
              )}
            
            </div>

            <div className="personal-form-group">
              <div className="personal-input-group">
                <select
                  className="select1"
                  value={profile.city}
                  onChange={(e) =>
                    setprofile({ ...profile, city: e.target.value })
                  }
                >
                  {CitiesListData &&
                    [{ name: "Choose the City" }, ...CitiesListData].map(
                      (item, i) => {
                        return (
                          <option value={item?.name} data-name={item?.name}>
                            {item?.name}
                          </option>
                        );
                      }
                    )}
                </select>
                <div className="dropdown-icon">
                  <FaCaretDown size={16} />
                </div>
              </div>
              <div className="personal-input-group">
                <select
                  className="select1"
                  value={profile.state}
                  onChange={(e) =>
                    setprofile({ ...profile, state: e.target.value })
                  }
                >
                  {StateListData &&
                    [{ name: "Choose the State" }, ...StateListData].map(
                      (item, i) => {
                        return (
                          <option value={item?.name} data-name={item?.name}>
                            {item?.name}
                          </option>
                        );
                      }
                    )}
                </select>
                <div className="dropdown-icon">
                  <FaCaretDown size={16} />
                </div>
              </div>
              <div className="personal-input-group">
                <select
                  className="select1"
                  value={profile.country}
                  onChange={(e) =>
                    setprofile({ ...profile, country: e.target.value })
                  }
                >
                  {CountryListData &&
                    [{ name: "Choose the Country" }, ...CountryListData].map(
                      (item, i) => {
                        return (
                          <option value={item?.name} data-name={item?.name}>
                            {item?.name}
                          </option>
                        );
                      }
                    )}
                </select>
                <div className="dropdown-icon">
                  <FaCaretDown size={16} />
                </div>
              </div>
            </div>
            {isEditingLocation && (

<div className="company-form-actions">
          <button className="company-cancel-btn" onClick={() => setIsEditingLocation(false)}>
         Cancel
          </button>
          <button className="company-save-btn" onClick={handleSaveProfile}>
           Save Changes
          </button>
        </div>
          )
            }
          </div>

          {/* Social Media Account Section */}
          {/* <div className="social-media-details">
            <h3>Social Media Account</h3>
            <div className="social-links">
              <button className="social-btn linkedin">🔗 Link Account</button>
              <button className="social-btn x">❌ Link Account</button>
              <button className="social-btn google">🌐 Link Account</button>
            </div>
          </div> */}
        </div>
      )}

      {/* Change Password Section */}
      {activeTab === "change-password" && (
        <div className="change-password-section">
        <h3>Change Password</h3>
  
        <div className="setting-password-form">
          <input type="text" name="fakeusernameremembered" style={{ display: "none" }} />
          <input type="password" name="fakepasswordremembered" style={{ display: "none" }} />
  

          <div className="personal-input-group">
            <label>Old Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword.old ? "text" : "password"}
                placeholder="Enter old password"
                autoComplete="new-password"
              />
              <span onClick={() => togglePassword("old")} className="eye-icon">
                {showPassword.old ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <div className="personal-input-group">
            <label>New Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword.new ? "text" : "password"}
                placeholder="Enter new password"
                autoComplete="new-password"
              />
              <span onClick={() => togglePassword("new")} className="eye-icon">
                {showPassword.new ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
  

          <div className="personal-input-group">
            <label>Confirm Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword.confirm ? "text" : "password"}
                placeholder="Confirm new password"
                autoComplete="new-password"
              />
              <span onClick={() => togglePassword("confirm")} className="eye-icon">
                {showPassword.confirm ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
        </div>
  
        <div className="setting-password-actions">
          <button className="setting-cancel-btn">Cancel</button>
          <button className="setting-save-btn">Save Changes</button>
        </div>
      </div>
      )}

      {/* Other Preferences Section */}
      {activeTab === "other-preferences" && (
        <div className="other-preferences-section">
          <h3>Other Preferences</h3>
        </div>
      )}
    </div>
  );
};

export default PersonalSettingsForm;
