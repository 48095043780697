import React, { useState } from "react";
import "./AtsDashBoardHeader.css";
import WinspireHeader from "./WinspireHeader";
import { useSelector } from "react-redux";
import { useGetCustomerMenuItemQuery } from "../../Redux/API/atsSlice";
import { BASE_URL } from "../../Redux/API/apiSlice";
import { useNavigate } from "react-router-dom";
import usePrivilege from "../Privileges/Privileges";

const AtsDashBoardHeader = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isAuth = useSelector((state) => state.auth);
  const { data } = useGetCustomerMenuItemQuery({ domain_name: isAuth?.user.domain_name });
  const main_header = data?.message?.find((section) => section.header_name === "menu_header")?.items || [];
  const navigate = useNavigate();



  const handleIconClick = (e, index) => {
    e.preventDefault();
    setActiveIndex(index);
    navigate(`${filteredMenuItems[index]?.route_name}`);
  };
  const isJob = usePrivilege('Job')
  const isDept = usePrivilege('Department')
  const isInterview = usePrivilege('Interview')
  const isCandidate = usePrivilege('Candidate')

  const filteredMenuItems = main_header.filter((item) => {
    const userId = isAuth?.user?.user_id;
  
    if (item.name_of_the_menu === 'Dashboard') return true;
    if (item.name_of_the_menu === 'Jobs') return isJob.includes(userId);
    if (item.name_of_the_menu === 'Candidates') return isCandidate.includes(userId);
    if (item.name_of_the_menu === 'Departments') return isDept.includes(userId);
    if (item.name_of_the_menu === 'Interviews') return isInterview.includes(userId);
  
    return true; // show other menus by default
  });
  


  return (
    <div>
  <WinspireHeader />  {/* This stays at the very top */}
  <div className="atsdashboardheader-wrapper">
    <div className="atsdashboardheader-container">
      <div className="atsdashboardheader-icon-menu">
      {filteredMenuItems.map((item, index) => (
  <div
    key={item.id}
    className={`atsdashboardheader-icon-wrapper ${activeIndex === index ? "active" : ""}`}
  >
    <button
      className={`atsdashboardheader-icon-button ${activeIndex === index ? "active" : ""}`}
      aria-label={item.name_of_the_menu}
      onClick={(e) => handleIconClick(e, index)}
    >
      <img
        src={`${BASE_URL}${item.icon}`}
        alt={item.name_of_the_menu}
        className="atsdashboardheader-menu-icon"
      />
      <span className="menu-label">{item.name_of_the_menu}</span>
    </button>
    <span className="atsdashboardheader-tooltip">{item.name_of_the_menu}</span>
  </div>
))}

      </div>
    </div>
  </div>
</div>
  );
};

export default AtsDashBoardHeader;