
import React, { useEffect, useRef, useState } from "react";
import DynamicTextInput from "../DynamicInputs/DynamicTextInput";
import {
  useCreateJobPostingMutation,
  useGetCustomerLayoutQuery,
  useGetCustomTemplateNameQuery,
  useGetCustomerTemplateByIdQuery,
  useCreateInterviewsMutation,
} from "../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { Buffer } from "buffer";
// import EditCreateJobLayout from "./EditCreateJobLayout";
import DraggableList from "react-draggable-list";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOptimistic } from "react";
// import "./CreateJob.css";
import { showToast } from "../ToastContainer/ToastProvider";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import usePrivilege from "../Privileges/Privileges";


const CreateInterview = () => {
  const [DropdownChange, setDropDownChange] = useState();
  const isAuth = useSelector((state) => state.auth);
  const [dropdownValue, setDropdownValue] = useState([]);
  const [handleInput, setHandleInput] = useState([]);
  const [customInputs, setCustomInput] = useState([]);
    const [isCreateJobOpen, setIsCreateJobOpen] = useState(false);
  const [Remove, setRemove] = useState(false);
  const [createInterview] = useCreateInterviewsMutation();
  const MovableInput = useRef();
  const { data, isSuccess } = useGetCustomerLayoutQuery({
    domain_name: isAuth?.user.domain_name,
  });

  const [params] = useSearchParams();

  const { data: customLayoutNameData, isSuccess: CustomLayoutNameSuccess } =
    useGetCustomTemplateNameQuery({
      domain_name: isAuth?.user.domain_name,
      moduleName: params.get("type") ? params.get("type") : null,
    });
console.log("template name",customLayoutNameData)

    function formatDateForMySQL(dateString) {
      if (!dateString || dateString.trim() === '') {
          return null; // Return NULL if the value is empty
      }
  
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
          return null; // Return NULL if invalid date
      }
  
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
  
      return `${year}-${month}-${day}`; // Return formatted YYYY-MM-DD
  }
  // console.log("templatename",customLayoutNameData)
  const privilege=usePrivilege('Interview Create')

  const {
    data: customerLayoutIdData,
    isSuccess: CustomerLayoutIdSuccess,
    refetch: CustomerLayoutIdRefetch,
  } = useGetCustomerTemplateByIdQuery({
    domain_name: isAuth?.user.domain_name,
    // templateId:params.get('type')?params.get('type'):null
    templateId: DropdownChange || null,
  });

  console.log(" customerr layout",customerLayoutIdData)
  useEffect(() => {
    CustomerLayoutIdRefetch();
    if (
      customerLayoutIdData?.status &&
      Array.isArray(customerLayoutIdData?.message) &&
      customerLayoutIdData?.message.length > 0
    ) {
      setCustomInput((prev) => {
        const sortedArray = [...customerLayoutIdData.message].sort(
          (a, b) => a.index_of - b.index_of
        );
        const updateArray = sortedArray.map((item, i) => ({
          ...item,
          index_of: i + 1,
          fields:
            item.fields?.map((child, index) => ({
              ...child,
              index_of: index + 1,
            })) || [],
        }));
        return updateArray;
      });
    } else {
      setCustomInput([]);
    }
  }, [CustomerLayoutIdSuccess, customerLayoutIdData]);

  useEffect(() => {
    if (customLayoutNameData?.status && customLayoutNameData?.message) {
      setDropdownValue(customLayoutNameData?.message || []);

      setDropDownChange(customLayoutNameData?.message[0]?.template_id || [])

    }
  }, [CustomLayoutNameSuccess]);
  const handleListener = async () => {
    try {
      const data = {};
      customInputs &&
        customInputs.forEach((section, i) => {
          section?.fields.forEach((item) => {
            if (item?.datatype === "boolean") {
              data[`${item?.ColumnName}`] =
                item?.valueOftheField === "true"
                  ? true
                  : item?.valueOftheField === "false"
                  ? false
                  : item?.valueOftheField === true
                  ? true
                  : false;
            } else if (item?.datatype === "int") {
              data[`${item?.ColumnName}`] = parseInt(item?.valueOftheField);
            } 
            else if(item?.datatype==='date'){
              data[`${item?.ColumnName}`] = formatDateForMySQL(item?.valueOftheField)
          }
            else {
              data[`${item?.ColumnName}`] = item?.valueOftheField;
            }
          });
        
        });
      
      try {
        const response = await createInterview({
          domain_name: isAuth?.user.domain_name,
          created_by:`${isAuth?.user?.firstname} ${isAuth?.user?.lastname}`,
          user_id:isAuth?.user?.user_id,
          data: {
            data,
            customInputs,
          },
        });
        // console.log("Res", response)
        showToast(response.data.status, response.data.message);
        // if (response?.data?.status) {
        //     alert("Job Create Successfully")
        // }
        const clearFieldValues = () => {
          const updatedCustomInputs = customInputs.map((section) => {
            return {
              ...section,
              fields: section.fields.map((field) => {
                // Reset the value of the field based on its type
                // if (field?.typeOftheField === 'dropdownInput' || field?.typeOftheField === 'multiselectInput') {
                //   return {
                //     ...field,
                //     valueOftheField: setDropDownChange(""),  // Or null if you prefer
                //   };
                // }

                if (field?.typeOftheField === 'numberInput'|| field?.typeOftheField === 'textInput' || field?.typeOftheField === "editorInput") {
                  return {
                    ...field,
                    valueOftheField: null 
                  };
                }
                
                if (field?.typeOftheField === 'dateInput') {
                  return {
                    ...field,
                    valueOftheField: ""  
                  };
                }
                
                if (field?.typeOftheField === 'checkboxInput') {
                  return {
                    ...field,
                    valueOftheField: false  
                  };
                }
                
                return field;
              }),
            };
          });
        
          setCustomInput(updatedCustomInputs);  
        };
        clearFieldValues();
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDropdownChange = (e) => {
    setDropDownChange(e.target.value);
  };
  const navigate = useNavigate();
  const handleEditClick = (e) => {
    if (DropdownChange && DropdownChange !== "0") {
      const isValidTemplate = dropdownValue.some(
        (item) => item.template_id === DropdownChange
      );

      if (isValidTemplate) {
        navigate(`/ats/dashboard/?type=editLayout&id=${DropdownChange}`, {
          state: {
            moduleName: params.get("type") ? params.get("type") : null,
          },
        });
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const toggleCreateJobMenu = () => {
    setIsCreateJobOpen(!isCreateJobOpen);
  };

  // console.log("cusss", customInputs)
  return (
    <>
      <div className="create-job-container">
        <div
                  className="create-job-header-container-oop"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* Breadcrumb Navigation */}
                  <div className="create-job-breadcrumb" style={{ flex: 1 }}>
                    <a
                      href={`?type=job_openings`}
                      className="create-job-breadcrumb-job-opening"
                    >
                      Job opening
                    </a>
                    <span className="create-job-chevron">
                      <MdKeyboardArrowRight size={23} />
                    </span>
                    <a className="create-job-breadcrumb-link">Create Interview</a>
                    <span className="create-job-chevron">
                      <MdKeyboardArrowRight size={23} />
                    </span>
                    <a className="create-job-breadcrumb-link">Default Template</a>
                  </div>
        
                  {/* Dropdown & Edit Button on Right */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      flex: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* Select Template & Dropdown in the same row */}
                    <div
                      className="create-job-templateSelection-container"
                      style={{ display: "flex", alignItems: "center", gap: "2px" }}
                    >
                      <label
                        htmlFor="template-dropdown"
                        className="select-template-label"
                      >
                        Select Template:
                      </label>
                      <select
                        className="create-job-dropdown"
                        id="template-dropdown"
                        value={DropdownChange}
                        onChange={(e) => {
                          setDropDownChange(
                            e.target.options[e.target.selectedIndex].value
                          );
                        }}
                        style={{ width: "250px", minWidth: "200px", padding: "8px" }} // Fixed dropdown width
                      >
                        {dropdownValue &&
                          dropdownValue.map((item, i) => (
                            <option value={item?.template_id} key={i}>
                              {item.template_name}
                            </option>
                          ))}
                      </select>
                    </div>
        
                    {/* Edit Button with more spacing */}
                    <div>
                      <a href={`/ats/dashboard/?type=editLayout&id=${DropdownChange}`}>
                        <button
                          onClick={handleEditClick}
                          className="select-template-editbtn"
                        >
                          Edit
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
        <div>
          {customInputs &&
            customInputs.map((parent_item, parent_index) => {
              return (
                <>
                  <p style={{ fontWeight: "bold", fontSize: "15pt" }}>
                    {parent_item?.section}
                  </p>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {parent_item?.fields &&
                      parent_item?.fields.map((item, i) => {
                        const isEditor = item?.typeOftheField === "editorInput"; 

                        return (
                          <div
                            key={i}
                            style={{
                              flex: isEditor ? "1 1 100%" : "1 1 23%", 
                              minWidth: isEditor ? "100%" : "200px", 
                              padding: "5px",
                            }}
                          >
                            <DynamicTextInput
                              className={`border-b-2 focus:outline-none flex-1 mr-2 ${
                                isEditor ? "editor-class" : ""
                              }`}
                              parentInd={parent_index}
                              remove={Remove}
                              onChangeRemove={setRemove}
                              index={i}
                              {...item}
                              value={customInputs}
                              customInputs={customInputs}
                              onChangeCustomInput={setCustomInput}
                              onChangeValue={setCustomInput}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            })}
          <button
            onClick={() => {
              handleListener();
            }}
            className="create-job-button"
            style={
              privilege.includes(isAuth?.user?.user_id)
                ? { display: "block" }
                : { display: "none" }
            }
          >
            Schedule Interview
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateInterview;
