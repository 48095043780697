import React, { useState ,useEffect} from "react";
import "./CompanyDetailForm.css";
import ReactQuill from "react-quill";
import { useGetCompanyDetailsQuery, useUpdateCompanyDetailsMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import { Edit, Save } from "lucide-react";
import { FaEdit } from "react-icons/fa";
import { useGetCountryListQuery } from "../../../../Redux/API/homeSlice";
import { MdOutlineCancel } from "react-icons/md";

const CompanyDetailForm = () => {
    const [activeTab, setActiveTab] = useState("general-details");
    const [isEditing, setIsEditing] = useState(false);
    const [CountryListData, setCountryList] = useState();
    const [timeFormat, setTimeFormat] = useState("24");
    const isAuth = useSelector((state) => state.auth);


  
    const {
        data: CountryList,
        isSuccess: CountrySuccess,
        refetch: CountryRefetch,
      } = useGetCountryListQuery();

    const { data ,refetch} = useGetCompanyDetailsQuery({
      domain_name: isAuth?.user?.domain_name
    });
    
useEffect(()=>{
refetch();
},[])

    // Initial Company Data from API
    const CompanyData = data?.message[0] || {};

    const [formData, setFormData] = useState({
      company_name: "",
      display_name: "",
      website: "",
      contact_number: "",
      hsn_sac_code: "",
      pan_number: "",
      description: "",
      country_code:""
    });
  // console.log("formdataa",formData);
  
    useEffect(() => {
      if (data?.status) {
        setFormData({
          company_name: CompanyData.company_name || "",
          display_name: CompanyData.display_name || "",
          website: CompanyData.website || "",
          contact_number: CompanyData.contact_number || "",
          hsn_sac_code: CompanyData.hsn_sac_code || "",
          pan_number: CompanyData.pan_number || "",
          description: CompanyData.description || "",
          country_code:CompanyData.country_code || "",
        });
      }
    }, [CompanyData]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleDescriptionChange = (value) => {
      setFormData((prev) => ({
        ...prev,
        description: value,
      }));
    };
  
    const handleEdit = () => {
      setIsEditing(true);
    };

    const [updateCompanyDetails] = useUpdateCompanyDetailsMutation();

    const handleSave = async () => {
        try {
          const response = await updateCompanyDetails({
            data: formData,
            domain_name: isAuth?.user?.domain_name,
          }).unwrap();
      
          // console.log("Company details updated successfully ===>", response);
      
          setFormData(response.updatedCompanyDetails || formData);
          setIsEditing(false);
        } catch (error) {
          console.error("Error updating company details:", error);
        }
      };


  

  return (
    <div className="company-details-container">
      <div className="setting-company-tabs">
        <button
          className={activeTab === "general-details" ? "active" : ""}
          onClick={() => setActiveTab("general-details")}
        >
          General Details
        </button>
        {/* <button
          className={activeTab === "branding" ? "active" : ""}
          onClick={() => setActiveTab("branding")}
        >
          Branding
        </button>
        <button
          className={activeTab === "locale-information" ? "active" : ""}
          onClick={() => setActiveTab("locale-information")}
        >
          Locale Information
        </button> */}
      </div>

      {/* General Details Tab */}
      {activeTab === "general-details" && (
  <>
    <div style={{ display: "flex", gap: "1%", alignItems: "center", backgroundColor: "transparent" }}>
      <h4 style={{ color: "black" }}>General Information</h4>
      {!isEditing && (
        <button className="company-edit-btn" style={{ backgroundColor: "transparent" }} onClick={handleEdit}>
          <FaEdit className="company-setting-edit" />
        </button>
      )}
    </div>

    <div className="general-details-section">
      <div className="company-detail-form-group">
        <div className="comapany-input-group">
          <label>Company Name</label>
          <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} readOnly={!isEditing} />
        </div>
        <div className="comapany-input-group">
          <label>Display Name</label>
          <input type="text" name="display_name" value={formData.display_name} onChange={handleChange} readOnly={!isEditing} />
        </div>
      </div>

      <div className="company-detail-form-group">
        <div className="comapany-input-group">
          <label>Website</label>
          <input type="text" name="website" value={formData.website} onChange={handleChange} readOnly={!isEditing} />
        </div>
        <div className="comapany-input-group">
          <label>Company Contact</label>
          <div className="company-mobile-input">
            <select value={formData.country_code} onChange={(e) => setFormData({ ...formData, country_code: e.target.value })}>
              {CountryList?.status &&
                CountryList?.message.map((item, i) => (
                  <option key={i} value={item?.phonecode}>
                    {item?.phonecode}
                  </option>
                ))}
            </select>

            <input type="text" name="contact_number" value={formData.contact_number} onChange={handleChange} readOnly={!isEditing} />
          </div>
        </div>
      </div>

      <div className="company-detail-form-group">
        <div className="comapany-input-group">
          <label>HSN/SAC Code</label>
          <input type="text" name="hsn_sac_code" value={formData.hsn_sac_code} onChange={handleChange} readOnly={!isEditing} />
        </div>
        <div className="comapany-input-group">
          <label>PAN Number</label>
          <input type="text" name="pan_number" value={formData.pan_number} onChange={handleChange} readOnly={!isEditing} />
        </div>
      </div>

      <div className="comapany-input-group setting-description">
        <label>Description</label>
        <ReactQuill value={formData.description} onChange={handleDescriptionChange} theme="snow" readOnly={!isEditing} />
      </div>

      {/* Save & Cancel Buttons at Bottom */}
      {isEditing && (
        <div className="company-form-actions">
          <button className="company-cancel-btn" onClick={() => setIsEditing(false)}>
         Cancel
          </button>
          <button className="company-save-btn" onClick={handleSave}>
           Save Changes
          </button>
        </div>
      )}
    </div>
  </>
)}


      {/* Branding Tab */}
      {activeTab === "branding" && (
        <div className="branding-section">
          <div className="branding-upload-container">
            <div className="upload-box">
              <label>Company Logo</label>
              <div className="drop-area">
                <p>Drag & drop or click to choose files</p>
                <span>Size: 512px x 512px</span>
              </div>
            </div>
            <div className="upload-box">
              <label>Social Share Image</label>
              <div className="drop-area">
                <p>Drag & drop or click to choose files</p>
                <span>Size: 512px x 512px</span>
              </div>
            </div>
          </div>

          <div className="branding-form-actions">
            <button className="branding-cancel-btn">Cancel</button>
            <button className="branding-save-btn">Save</button>
          </div>
        </div>
      )}

      {/* Locale Information Tab */}
      {activeTab === "locale-information" && (
        <div className="locale-info-section">
          <div className="locale-row">
            <div className="locale-field">
              <label>Country of Operations</label>
              <select>
                <option>India</option>
                <option>USA</option>
                <option>UK</option>
                <option>Australia</option>
              </select>
            </div>
            <div className="locale-field">
              <label>Currency</label>
              <input type="text" value="INR" readOnly />
            </div>
          </div>

          <div className="locale-row">
            <div className="locale-field">
              <label>Time Zone</label>
              <select>
                <option>(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                <option>(UTC-08:00) Pacific Time (US & Canada)</option>
                <option>(UTC+00:00) London</option>
                <option>(UTC+01:00) Berlin, Paris</option>
              </select>
            </div>
            <div className="locale-field">
              <label>Date Format</label>
              <select>
                <option>dd/mm/yy</option>
                <option>mm/dd/yy</option>
                <option>yyyy-mm-dd</option>
              </select>
            </div>
          </div>

          <div className="locale-time-format">
            <label>Time Format</label>
            <div className="time-format-options">
              <label>
                <input type="radio" value="24" checked={timeFormat === "24"} onChange={() => setTimeFormat("24")} />
                24 Hours
              </label>
              <label>
                <input type="radio" value="12" checked={timeFormat === "12"} onChange={() => setTimeFormat("12")} />
                12 Hours
              </label>
            </div>
          </div>

          <div className="locale-form-actions">
            <button className="locale-cancel-btn">Cancel</button>
            <button className="locale-save-btn">Save</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDetailForm;
