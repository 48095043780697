import apiSlice from "./apiSlice";


export const atsSlice = apiSlice.injectEndpoints({
    overrideExisting:true,
    endpoints:builder =>({
       getCustomerMenuItem: builder.query({
            query:({domain_name=''}) => ({
                url:`/customer/getCustomerMenuItems?customerName=${domain_name}`,
                method:'GET'
            })
       }),
       getCustomerLayout:builder.query({
            query:({domain_name=''}) =>({
                url:`/customer/getCustomerLayout?customerName=${domain_name}`,
                method:'GET'
            })
       }),
       getCustomTemplateName:builder.query({
        query:({domain_name='',moduleName=''})=>({
            url:`customer/getTemplateName?customerName=${domain_name}&moduleName=${moduleName}`,
            method:'GET'
        })
     }),
       getCustomerLayoutInputs:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getCustomerLayoutInputs?customerName=${domain_name}`,
            method:'GET'

        })
       }),
       getCustomTemplateName:builder.query({
        query:({domain_name='',moduleName=''})=>({
            url:`customer/getTemplateName?customerName=${domain_name}&moduleName=${moduleName}`,
            method:'GET'
        })
       }),
       getCustomerTemplateById: builder.query({
        query:({domain_name='',templateId=''})=>({
            url:`customer/getTemplateById?customerName=${domain_name}&templateId=${templateId}`,
            method:'GET'
        })
       }),

       createJobPosting:builder.mutation({
        query:({domain_name='',data='',created_by='',user_id=''})=>({
            url:`/customer/createCustomerJobs?customerName=${domain_name}&created_by=${created_by}&user_id=${user_id}`,
            method:'POST',
            body:data
        })
   }),
       getCustomerJobs : builder.query({
        query:({domain_name='',role_id='',user_id=''}) =>({
            url:`/customer/getCustomerJobs?customerName=${domain_name}&role_id=${role_id}&user_id=${user_id}`,
            method:'GET'
        })
       }),
       getCustomerJobsById:builder.query({
        query:({domain_name='',Jobid=''}) =>({
            url:`/customer/getCustomerJobById?customerName=${domain_name}&Jobid=${Jobid}`,
            method:'GET'
        })
       }),
       getCustomerJobUpdateById:builder.query({
            query:({domain_name='',Jobid=''}) =>({
                url:`/customer/getCustomerJobUpdateById?customerName=${domain_name}&Jobid=${Jobid}`,
                method:'GET'
            })
       }),
       posCustomerJobUpdateById:builder.mutation({
            query:({domain_name='',Jobid='',data=''}) =>({
              url:`/customer/postCustomerJobUpdateById?customerName=${domain_name}&Jobid=${Jobid}`,
              method:'POST',
              body:data
            })
       }),
       getDropDownData:builder.query({
            query:({domain_name='',id=''}) =>({
                url:`/customer/getCustomerDropDownData?customerName=${domain_name}&id=${id}`,
                method:'GET'
            }),
            keepUnusedDataFor:0
       }),
       postCustomCountry:builder.mutation({
        query:({domain_name='',countryName=''}) =>({
            url:`/customer/postCustomCountry?customerName=${domain_name}`,
            method:'POST',
            body:{
                countryName:countryName
            }
        }),
       }),
       postCustomState:builder.mutation({
        query:({domain_name='',stateName='',countryId=''}) =>({
            url:`/customer/postCustomState?customerName=${domain_name}`,
            method:'POST',
            body:{
                stateName:stateName,
                countryId:countryId
            }
        }),
       }),
       postCustomCity:builder.mutation({
        query:({domain_name='',cityName='',stateId=''}) =>({
            url:`/customer/postCustomCity?customerName=${domain_name}`,
            method:'POST',
            body:{
                cityName:cityName,
                stateId:stateId
            }
        }),
       }),
       getDefaultTemplate : builder.query({
        query:({domain_name='',moduleName=''})=>({
            url:`customer/getDefaultTemplate?customerName=${domain_name}&moduleName=${moduleName}`,
            method:'GET'
        })
       }),
       postDropDowndata:builder.mutation({
        query:({domain_name='',data='',dropdown_id=''}) =>({
            url:`/customer/postDropDownData?customerName=${domain_name}`,
            method:'POST',
            body:{
                data:data,
                dropdown_id:dropdown_id
            }
        })
       }),
       putUpdateDropDownData :builder.mutation({
        query:({domain_name='',data='',dropdown_id=''}) =>({
            url:`/customer/updateDropDownData?customerName=${domain_name}`,
            method:'PUT',
            body:{
                data:data,
                dropdown_id:dropdown_id
            }
        })
       }),
       updateTemplateById: builder.mutation({
            query:({domain_name='',templateId='',moduleName='',templateName='',data='',template_name=''}) =>({
                url:`/customer/editTemplateById?customerName=${domain_name}&templateId=${templateId}&moduleName=${moduleName}&templateName=${templateName}`,
                method:'PUT',
                body:{
                    data:data,
                    template_name:template_name
                }
            })
       }),
       gettingTemplateNameById:builder.query({
        query:({domain_name='',templateId=''}) =>({
            url:`/customer/getTemplateNameById?customerName=${domain_name}&templateId=${templateId}`,
            method:'GET'
        })
       }),

       getDepartments:builder.query({
        query:({domain_name='',role_id='',user_id=''})=>({
            url:`/customer/getDepartments?customerName=${domain_name}&role_id=${role_id}&user_id=${user_id}`,
            method:'Get'
        })
       }),
       getDepartmentById:builder.query({
        query:({domain_name='',Deptid=''})=>({
            url:`/customer/getDeptEditById?customerName=${domain_name}&deptid=${Deptid}`,
            method:'GET'
        })
       }),
       getPrefixData:builder.query({
        query:({domain_name=''}) =>({
            url:`/customer/getPrefixData?customerName=${domain_name}`,
            method:'GET'
        })
       }),
       updatePrefix:builder.mutation({
            query:({domain_name='',data=''}) =>({
                url:`/customer/updatePrefix?customerName=${domain_name}`,
                method:'PUT',
                body:data
            })
       }),
       createDepartments:builder.mutation({
        query:({domain_name='',data='',created_by='',user_id=''}) =>({
            url:`/customer/createDepartments?customerName=${domain_name}&created_by=${created_by}&user_id=${user_id}`,
            method:'POST',
            body:data
        })
     }),
     getDepartmentDropDown:builder.query({
            query:({domain_name=''}) => ({
                url:`/customer/getDropDownDepartments?customerName=${domain_name}`,
                method:'GET'
            })
     }),
     updateEmbededCodeSettings:builder.mutation({
        query:({domain_name='',template_id='',data=''}) =>({
            url:`/customer/updateEmbededCodeSettings?customerName=${domain_name}&id=${template_id}`,
            method:'PUT',
            body:{
                data:data
            }
        })
     }),
    getEmbededCodeSettings:builder.query({
        query:({domain_name=''}) =>({
            url:`/customer/getEmbededCodeSettings?customerName=${domain_name}`,
            method:'GET'
        })
    }),

    postCustomerDeptUpdateById:builder.mutation({
        query:({domain_name='', Deptid='',data=''})=>({
            url:`/customer/updateDepartmentById?customerName=${domain_name}&deptid=${Deptid}`,
            method:'PUT',
            body:data

        })
    }),
    postContactInformation:builder.mutation({
        query:({data=''})=>({
            url:"/contactus",
            method:'POST',
            body:data
        })
    }),
    getAtsHomePage:builder.query({
        query:()=>({
         url:'/getLandingPage',
         method:'GET'
        }) 
     }),

    filterJobsByLatestOldest: builder.query({
        query: ({ domain_name = "",sortBy = "latest" }) => ({
            url: `/customer/filterJobsByOldestLatest?customerName=${domain_name}&sortBy=${sortBy}`,
            method: "GET"
        })
    }), 
    filterJobsByAllActions: builder.query({
        query: ({ domain_name, sortBy, filters, userId }) => {
          let queryParams = `customerName=${domain_name}&sortBy=${sortBy}`;
  
          if (filters.includes("my_jobs")) {
            queryParams += `&jobType=my_jobs&userId=${userId}`;
          }
          if (filters.includes("active")) {
            queryParams += `&jobType=active`;
          }
          if (filters.includes("inactive")) {
            queryParams += `&jobType=inactive`;
          }
          if (filters.includes("recent")) {
            queryParams += `&jobType=recent`;
          }
  
          return `/customer/filterJobs?${queryParams}`;
        },
      }),          
     getSkills:builder.query({
        query:({domain_name=''}) =>({
            url:`/customer/getSkills?customerName=${domain_name}`,
            method:'GET'
        })
     }),
     postCandidate:builder.mutation({
        query:({domain_name='',data='',created_by='',user_id=''})=>({
            url:`/customer/createCandidate?customerName=${domain_name}&created_by=${created_by}&user_id=${user_id}`,
            method:'POST',
            body:data
        })
     }),
     getCustomerCandidates : builder.query({
        query:({domain_name='',role_id='',user_id=''}) =>({
            url:`/customer/getCandidates?customerName=${domain_name}&role_id=${role_id}&user_id=${user_id}`,
            method:'GET'
        })
       }),
       getCandidateById:builder.query({
        query:({domain_name='',candidateid=''})=>({
            url:`/customer/getCandidateById?customerName=${domain_name}&candidateid=${candidateid}`,
            method:'GET'
        })
       }),
       AssignJobtoACandidate:builder.mutation({
        query:({domain_name='',data='',candidate_id=''}) =>({
            url:`/customer/AssignJobtoACandidate?customerName=${domain_name}&candidate_id=${candidate_id}`,
            method:'POST',
            body:data
        })
       }),
       getAssignedJobs:builder.query({
        query:({domain_name='',candidate_id=''})=>({
            url:`/customer/getAssignedJobs?customerName=${domain_name}&candidate_id=${candidate_id}`,
            method:'GET'
        })
       }),
       getCandidateUpdateById:builder.query({
            query:({domain_name='',candidate_id=''})=>({
                url:`/customer/getCandidateUpdateById?customerName=${domain_name}&candidateid=${candidate_id}`,
                method:'GET'
            })
       }),
       getCandidateSkill:builder.query({
            query:({domain_name='',candidate_id=''})=>({
                url:`/customer/getCandidateSkill?customerName=${domain_name}&candidateid=${candidate_id}`,
                method:'GET'
            })
       }),
       updateCandidate:builder.mutation({
        query:({ domain_name='', data='',candidate_id='',user_id='' })=>({
            url:`/customer/updateCandidateById?customerName=${domain_name}&candidateid=${candidate_id}&user_id=${user_id}`,
            method:'PUT',
            body:data
        }),
       }),
       createInterviews:builder.mutation({
        query:({domain_name='',data='',created_by='',user_id=''})=>({
            url:`/customer/createInterviews?customerName=${domain_name}&created_by=${created_by}&user_id=${user_id}`,
            method:'POST',
            body:data
        })
    }),
    getUpdateInterviewById:builder.query({
        query:({domain_name='',interviewid=''})=>({
            url:`/customer/getInterviewUpdateById?customerName=${domain_name}&interviewid=${interviewid}`,
            method:'GET'
        })
    }),
    updateInterviewById:builder.mutation({
        query:({domain_name='',interviewid='',data=''})=>({
            url:`/customer/updateInterviewById?customerName=${domain_name}&interviewid=${interviewid}`,
            method:'PUT',
            body:data

        })
    }),
    getcustomerInterviews:builder.query({
        query:({domain_name='',role_id='',user_id=''})=>({
            url:`/customer/getInterviews?customerName=${domain_name}&role_id=${role_id}&user_id=${user_id}`,
            method:'GET'
        })
    }),
    getcustomerRole:builder.query({
        query:({domain_name=''})=>({
            url:`customer/getRole?customerName=${domain_name}`,
            method:'GET'
        })
    }),
    getUserHavePrivilege:builder.query({
        query:({domain_name='',roleid='',userid='',module=''})=>({
            url:`/customer/getUserHavePrivilege?customerName=${domain_name}&roleid=${roleid}&userid=${userid}&module=${module}`,
            method:'GET'
        })
    }),
    getRoleById:builder.query({
        query:({domain_name='',roleid=''})=>({
            url:`/customer/getRoleById?customerName=${domain_name}&roleid=${roleid}`,
            method:'GET'
        })
    }),
    createRole:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/createRole?customerName=${domain_name}`,
            method:'POST',
            body:data
        })
     }),
     getRole:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getRole?customerName=${domain_name}`,
            method:'GET'
        })
     }),
     getPrivilegeType:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getPrivilegeType?customerName=${domain_name}`,
            method:'GET'
        })
     }),
     createUser:builder.mutation({
        query:({domain_name='',firstname='', lastname='', email_id='', password='', created_by='',role_name='',role_id='',phonecode='',mobilenumber='',reporting_manager='',dottedlinemanager=''})=>({
            url:`/customer/createUser?customerName=${domain_name}`,
            method:'POST',
            body:{
                firstname:firstname,
                lastname:lastname,
                domain_name:domain_name,
                email_id:email_id,
                password:password,
                created_by:created_by,
                role_name:role_name,
                role_id:role_id,
                phonecode:phonecode,
                mobilenumber:mobilenumber,
                reporting_manager:reporting_manager,
                dottedlinemanager:dottedlinemanager
            }
        })
     }),
     getUsers:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getUsers?customerName=${domain_name}`,
            method:'GET'
        })
     }),
     createGroups:builder.mutation({
        query:({domain_name='',data='',user=''}) =>({
            url:`/customer/createGroups?customerName=${domain_name}&user=${user}`,
            method:'POST',
            body:data
        })
     }),
     getGroups:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getGroups?customerName=${domain_name}`,
            method:'GET'
        })
     }),
    
     getHierachy:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getHierachy?customerName=${domain_name}`,
            method:'GET'
        })
     }),

     createHierachy:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/createHierachy?customerName=${domain_name}`,
            method:'POST',
            body:data
        })
     }),
     updatePrivileges:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/updatePrivileges?customerName=${domain_name}`,
            method:'PUT',
            body:data
        })
     }),
     getUserProfile: builder.query({
        query: ({ user_id = '' }) => ({
          url: `/customer/getUserProfile?user_id=${user_id}`,
          method: 'GET'
        })
      }),
      updateProfile: builder.mutation({
        query: ({ user_id='', data='',domain_name=''}) => ({
          url: `/customer/updateProfile?user_id=${user_id}&customerName=${domain_name}`,
          method: 'PUT',
          body: data,
        }),
      }),
      getCompanyDetails: builder.query({
        query: ({ domain_name = '' }) => ({
          url: `/customer/getCompanyDetails?customerName=${domain_name}`,
          method: 'GET'
        })
      }),
      updateCompanyDetails: builder.mutation({
        query: ({ data='',domain_name=''}) => ({
          url: `/customer/updateCompanyDetails?&customerName=${domain_name}`,
          method: 'PUT',
          body: data,
        }),
      }),
     getActivity:builder.query({
        query:({domain_name='',id=''})=>({
            url:`customer/getActivities?customerName=${domain_name}&id=${id}`,
            method:'GET'
        })
     }),
     getCandidateSetting:builder.query({
        query:({domain_name=''})=>({
            url:`customer/getCandidateSettings?customerName=${domain_name}`,
            method:'GET'

        })
     }),
     getCardsData:builder.query({
        query:({domain_name=''})=>({
            url:`customer/getCardsData?customerName=${domain_name}`,
            method:'GET'
        })
     }),
     sendCandidateEmail:builder.mutation({
        query:({domain_name='',candidateid='',data=''})=>({
            url:`customer/sendCandidateEmail?customerName=${domain_name}&candidateid=${candidateid}`,
            method:'POST',
            body:data
        })
     }),
     getLoginSettings:builder.query({
        query:({domain_name=''})=>({
            url:`customer/getLoginSettings?customerName=${domain_name}`,
            method:'GET'

        })
     }),
     getSettings:builder.query({
        query:({domain_name=''})=>({
            url:`customer/getSettings?customerName=${domain_name}`,
            method:'GET'
        })
     }),
     getCandidateInterviews:builder.query({
        query:({domain_name='',id=''})=>({
            url:`/customer/getCandidateInterviews?customerName=${domain_name}&candidateid=${id}`,
            method:'GET'
        })
     }),
     getCandidateJobs:builder.query({
        query:({domain_name='',id=''})=>({
            url:`/customer/getCandidateJob?customerName=${domain_name}&candidateid=${id}`,
            method:'GET'
        })
     }),
     getCandidateSettings:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getCandidateSettings?customerName=${domain_name}`,
            method:'GET'
        })
    }),
    updateCandidateSettings:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/updateCandidateSetting?customerName=${domain_name}`,
            method:'PUT',
            body:data
        })
    }),
    getLoginSettings:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getLoginSettings?customerName=${domain_name}`,
            method:'GET'
        })
    }),
    updateLoginSettings:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/updateLoginSettings?customerName=${domain_name}`,
            method:'PUT',
            body:data
        })
    }),
    getSettings:builder.query({
        query:({domain_name=''})=>({
            url:`/customer/getSettings?customerName=${domain_name}`,
            method:'GET'
        })
    }),
    updateSettings:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/updateSettings?customerName=${domain_name}`,
            method:'PUT',
            body:data
        })
    }),
    getFilterColumns:builder.query({
        query:({domain_name='',module_name=''})=>({
            url:`/customer/getFilterColumns?customerName=${domain_name}&module_name=${module_name}`,
            method:'GET'
        })
    }),
    updateFilterColumns:builder.mutation({
        query:({domain_name='',data=''})=>({
            url:`/customer/updateFilterColumns?customerName=${domain_name}`,
            method:'PUT',
            body:data
        })
    }),
    updateSMTP:builder.mutation({
        query:({domain_name='',data='',userid=''})=>({
            url:`/customer/updateSMTP?customerName=${domain_name}&userid=${userid}`,
            method:'PUT',
            body:data
        })
    }),
    getSMTPConfig:builder.query({
        query:({domain_name='',userid=''})=>({
            url:`/customer/getSMTPconfig?customerName=${domain_name}&userid=${userid}`,
            method:'GET'
        })
    }),
    sendTestEmail:builder.mutation({
        query:({domain_name='',userid='',email_data=''})=>({
            url:`/customer/testEmail?customerName=${domain_name}&userid=${userid}`,
            method:'POST',
            body:email_data
        }),
    }),
    postUploadDocument:builder.mutation({
        query:({domain_name='',candidateid='',data=''})=>({
            url:`customer/uploadDocuments?customerName=${domain_name}&candidateid=${candidateid}`,
            method:'POST',
            body:data

        })
    }),
    getCandidateDocuments:builder.query({
        query:({domain_name='',candidate_id=''})=>({
            url:`customer/getDocuments?customerName=${domain_name}&candidateid=${candidate_id}`,
            method:'GET'
        })
    })
    })
})

export const {
    useGetCustomerMenuItemQuery, 
    useGetCustomerLayoutQuery,
    useGetCustomerJobsQuery,
    useGetCustomerJobsByIdQuery,
    useGetCustomerJobUpdateByIdQuery,
    usePosCustomerJobUpdateByIdMutation,
    useCreateJobPostingMutation,
    useGetDropDownDataQuery,
    usePostCustomCountryMutation,
    usePostCustomStateMutation,
    usePostCustomCityMutation,
    useGetCustomTemplateNameQuery,
    useGetCustomerTemplateByIdQuery,
    useGetDefaultTemplateQuery,
    usePostDropDowndataMutation,
    usePutUpdateDropDownDataMutation,
    useUpdateTemplateByIdMutation,
    useGettingTemplateNameByIdQuery,
    useGetDepartmentsQuery,
    useGetDepartmentByIdQuery,
    useGetPrefixDataQuery,
    useUpdatePrefixMutation,
    useCreateDepartmentsMutation,
    useGetDepartmentDropDownQuery,
    useUpdateEmbededCodeSettingsMutation,
    useGetEmbededCodeSettingsQuery,
    usePostCustomerDeptUpdateByIdMutation,
    useGetAtsHomePageQuery,
    useFilterJobsByLatestOldestQuery,
    useFilterJobsByAllActionsQuery,
    useGetSkillsQuery,
    usePostCandidateMutation,
    useGetCustomerCandidatesQuery,
    useGetCandidateByIdQuery,
    usePostContactInformationMutation,
    useAssignJobtoACandidateMutation,
    useGetAssignedJobsQuery,
    useLazyGetAssignedJobsQuery,
    useGetCandidateUpdateByIdQuery,
    useGetCandidateSkillQuery,
    useUpdateCandidateMutation,
    useCreateInterviewsMutation, useGetUpdateInterviewByIdQuery,
    useUpdateInterviewByIdMutation,
    useGetcustomerInterviewsQuery,
    useGetcustomerRoleQuery,
    useGetUserHavePrivilegeQuery,
    useGetRoleByIdQuery,
    useLazyGetUserHavePrivilegeQuery,
    useCreateRoleMutation,
    useGetRoleQuery,
    useGetPrivilegeTypeQuery,
    useCreateUserMutation,
    useGetUsersQuery,
    useCreateGroupsMutation,
    useGetGroupsQuery,
    useGetHierachyQuery,
    useCreateHierachyMutation,
    useUpdatePrivilegesMutation,
    useGetUserProfileQuery,
    useUpdateProfileMutation,
    useGetCompanyDetailsQuery,
    useUpdateCompanyDetailsMutation,
    useGetActivityQuery,
    useGetCandidateSettingQuery,
    useGetCardsDataQuery,
    useSendCandidateEmailMutation,
    useGetCandidateInterviewsQuery,
    useGetCandidateJobsQuery
    ,useGetCandidateSettingsQuery,
    useUpdateCandidateSettingsMutation, useGetLoginSettingsQuery, useUpdateLoginSettingsMutation,
    useGetSettingsQuery, useUpdateSettingsMutation,
    useGetFilterColumnsQuery,useUpdateFilterColumnsMutation,
    useUpdateSMTPMutation, useGetSMTPConfigQuery,
    useSendTestEmailMutation,
    usePostUploadDocumentMutation,
    useGetCandidateDocumentsQuery
} = atsSlice
